import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

const NoticeBoardPostForm = ({ open, obj, onSubmit, handleClose }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (obj) {
      setTitle(obj.title);
      setContent(obj.content);
    }
  }, [obj]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{obj ? "Edit" : "Add"} Post</DialogTitle>
      <DialogContent>
        <DialogContentText>{obj ? "Edit" : "Add"} post.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Title"
          type="text"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          id="content"
          label="Content"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          onClick={() => onSubmit({ title, content })}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoticeBoardPostForm;
