export const TABLE_ACTIONS = {
  EDIT: "Edit",
  DELETE: "Delete",
};

export const testStatuses = {
  // Initial status:
  SCHEDULED: "SCHEDULED",
  // Started cases:
  IN_PROGRESS: "IN_PROGRESS",
  FINISHED: "FINISHED",
  // Cancelled cases:
  CANCELLED: "CANCELLED",
};

export const testUserStatuses = {
  // Not started cases:
  NOT_STARTED: "NOT_STARTED",
  // Started cases:
  IN_PROGRESS: "IN_PROGRESS",
  SUBMITTED: "SUBMITTED",
};

export const testStatusesLabel = {
  SCHEDULED: "Scheduled",
  IN_PROGRESS: "In Progress",
  FINISHED: "Finished",
  CANCELLED: "Cancelled",
  NOT_STARTED: "Not Attempted",
  SUBMITTED: "Submitted",
};

export const testStatusesColor = {
  SCHEDULED: "orange",
  IN_PROGRESS: "blue",
  FINISHED: "green",
  CANCELLED: "red",
  NOT_STARTED: "orange",
  SUBMITTED: "green",
};

export const QuestionTypes = {
  MCQ: "mcq",
  Code: "code",
};

export const testTypes = {
  PRACTICE: "PRACTICE",
  COLLEGE: "COLLEGE",
  COMPANY: "COMPANY",
};

export const preparationPlanModes = {
  STUDY: "study",
  PRACTICE: "practice",
};

export const FEATURES = [
  { name: "Job postings", value: "JOB_POSTINGS" },
  { name: "View content", value: "VIEW_CONTENT" },
  { name: "Watch videos", value: "WATCH_VIDEOS" },
  { name: "Practice tests", value: "PRACTICE_TESTS" },
  { name: "English training", value: "ENGLISH_TRAINING" },
  { name: "English bot", value: "ENGLISH_BOT" },
  { name: "Doubt support", value: "DOUBT_SUPPORT" },
  { name: "Mock interviews", value: "MOCK_INTERVIEWS" },
  { name: "Placement assistance", value: "PLACEMENT_ASSISTANCE" },
];

export const USER_ROLES = {
  STUDENT: "student",
  COLLEGE_ADMIN: "admin",
  COMPANY_ADMIN: "company_admin",
  STAFFADMIN: "staffadmin",
  SUPERADMIN: "superadmin",
};

export const TRANSACTION_STATUS = {
  success: { label: "Success", color: "green" },
  processing: { label: "Pending", color: "orange" },
  failed: { label: "Failed", color: "red" },
};

export const lockStatuses = {
  LOCKED: "LOCKED",
  UNLOCKED: "UNLOCKED",
  FINISHED: "FINISHED",
};

export const COUPON_TYPES = {
  EMI: "EMI",
  FLAT: "FLAT",
  PERCENTAGE: "PERCENTAGE",
};
