import { Checkbox, FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

export const AddCollegeForm = ({ open, handleAdd, handleClose }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [isTrial, setIsTrial] = useState(false);
  const [studentCredits, setStudentCredits] = useState(0);
  const [validTill, setValidTill] = useState(null);

  const onAdd = () => {
    handleAdd({
      name,
      email,
      location,
      isTrial,
      studentCredits,
      validTill,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New College</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="admin-email"
          label="Admin Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          id="location"
          label="Location"
          fullWidth
          variant="standard"
          multiline
          minRows={6}
          maxRows={8}
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <FormControlLabel
          label="Trial account"
          control={
            <Checkbox
              checked={isTrial}
              onChange={(e) => setIsTrial(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
        <TextField
          margin="dense"
          id="studentCredits"
          label="Student credits"
          type="number"
          fullWidth
          variant="standard"
          value={studentCredits}
          onChange={(e) => setStudentCredits(Number(e.target.value))}
        />

        {/* better to select a plan instead of a date. can have one plan for trial */}
        <TextField
          margin="dense"
          id="validTill"
          label="Valid Till"
          type="date"
          fullWidth
          variant="standard"
          value={validTill}
          onChange={(e) => setValidTill(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditCollegeForm = ({ open, obj, handleSubmit, handleClose }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    setName(obj?.name);
    setLocation(obj?.location);
  }, [obj]);

  const onSubmit = () => {
    handleSubmit({ name, location });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit College</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="location"
          label="Location"
          fullWidth
          variant="standard"
          multiline
          minRows={6}
          maxRows={8}
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
