import { Box, Typography } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

const Empty = ({ message = "It is empty here" }) => {
  return (
    <Box
      sx={{
        height: "80%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      variant="outlined"
    >
      <AllInclusiveIcon sx={{ m: 2 }} />
      <Typography variant="body1" component="div">
        {message}
      </Typography>
    </Box>
  );
};

export default Empty;
