import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import {
  createPreparationPlan,
  getPreparationPlans,
  updatePreparationPlan,
} from "../api/superAdmin";
import PreparationPlanDetails from "../components/PreparationPlanDetails";
import PreparationPlanForm from "../components/PreparationPlanForm";
import TableX from "../components/TableX";
import DialogX from "../components/utils/Dialog";

const columns = [
  { field: "_id", title: "ID", width: "10%" },
  { field: "title", title: "Title", width: "30%" },
];

const PreparationPlans = () => {
  const [preparationPlans, setPreparationPlans] = useState([]);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [errors, setErrors] = useState([]);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    getPreparationPlans().then((res) =>
      setPreparationPlans(res.data.preparationPlans)
    );
  }, []);

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    setAddFormOpen(false);
  };

  const onAddPreparationPlan = async (data) => {
    try {
      const res = await createPreparationPlan(data);
      setPreparationPlans([...preparationPlans, res.data.preparationPlan]);
      handleClose();
    } catch (err) {
      console.log(err);
      setErrors([err.response.data.message]);
    }
  };
  const onViewPreparationPlan = (id) => {
    setSelectedId(id);
  };
  const onEditPreparationPlan = (id) => {
    setSelectedId(id);
    setEditMode(true);
  };

  const onEditSubmit = async (data) => {
    try {
      const res = await updatePreparationPlan(selectedId, data);
      setPreparationPlans((prev) => {
        const index = prev.findIndex(
          (p) => p._id === res.data.preparationPlan._id
        );
        prev[index] = res.data.preparationPlan;
        return [...prev];
      });
      setSelectedId(null);
      setEditMode(false);
    } catch (err) {
      console.log(err);
      setErrors([err.response.data.message]);
    }
  };

  return (
    <>
      <TableX
        title="PreparationPlans"
        action={<Button onClick={handleClickOpen}>Add</Button>}
        rows={preparationPlans}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Edit",
            icon: <EditIcon fontSize="small" />,
            onClick: onEditPreparationPlan,
          },
          {
            title: "View",
            icon: <InfoOutlinedIcon fontSize="small" />,
            onClick: onViewPreparationPlan,
          },
        ]}
      />
      <DialogX
        open={addFormOpen}
        handleClose={() => setAddFormOpen(false)}
        title="Create Preparation Plan"
        content={
          <PreparationPlanForm
            errors={errors}
            onSubmit={onAddPreparationPlan}
          />
        }
      />
      <DialogX
        open={selectedId}
        handleClose={() => setSelectedId(null)}
        title={`Preparation Plan ${selectedId}`}
        content={
          editMode ? (
            <PreparationPlanForm
              errors={errors}
              obj={preparationPlans.find((p) => p._id === selectedId)}
              onSubmit={onEditSubmit}
            />
          ) : (
            <PreparationPlanDetails
              obj={preparationPlans.find((p) => p._id === selectedId)}
            />
          )
        }
      />
    </>
  );
};

export default PreparationPlans;
