import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { createStudent, editStudent, getStudents } from "../api/superAdmin";
import {
  AddStudentForm,
  EditStudentForm,
} from "../components/SuperAdminStudentForm";
import TableX from "../components/TableX";

import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import routes from "../layout/routes";

const columns = [
  {
    field: "name",
    title: "Name",
    width: "20%",
    render: (v, row) => (
      <Link to={routes.studentDetails.getPath(row._id)}>{v}</Link>
    ),
    onSort: (a, b) => a.name.localeCompare(b.name),
  },
  { field: "email", title: "e-mail", width: "20%" },
  {
    field: "mobileVerified",
    title: "Mobile Verified",
    width: "5%",
    render: (r) => {
      return r ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      );
    },
  },
  {
    field: "emailVerified",
    title: "Email Verified",
    width: "5%",
    render: (r) => {
      return r ? (
        <CheckCircleIcon color="success" />
      ) : (
        <CancelIcon color="error" />
      );
    },
  },
  {
    field: "createdAt",
    title: "Joined on",
    width: "20%",
    render: (_, row) => {
      // 20th Jan 2022
      const date = row?.createdAt || row?.updatedAt;
      if (!date) return "-";
      return dayjs(date).format("DD MMM YYYY");
    },
    onSort: (a, b) => {
      const aDate = a?.createdAt || a?.updatedAt;
      const bDate = b?.createdAt || b?.updatedAt;

      if (!aDate) return 1;
      if (!bDate) return -1;

      return dayjs(aDate).unix() - dayjs(bDate).unix();
    },
  },
  {
    field: "subscription",
    title: "Valid Till",
    width: "20%",
    render: (r) => {
      if (!r?.validTill)
        return <Typography color="red">Not Subscribed</Typography>;
      return dayjs(r.validTill).format("DD MMM YYYY");
    },
    onSort: (a, b) => {
      const aDate = a.subscription?.validTill;
      const bDate = b.subscription?.validTill;
      if (!aDate) return 1;
      if (!bDate) return -1;

      return dayjs(aDate).unix() - dayjs(bDate).unix();
    },
  },
];

const SuperAdminStudents = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [students, setStudents] = useState([]);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const query = useMemo(() => search.get("search") || "", [search]);

  useEffect(() => {
    setSearchQuery(query);
    getStudents({ search: query }).then((res) => {
      setStudents(res.students);
    });
  }, [query]);

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    setAddFormOpen(false);
  };

  const onAddStudent = (data) => {
    createStudent(data).then((res) => {
      handleClose();
    });
  };
  const onEditStudent = (id) => {
    setEditId(id);
  };
  const onEditSubmit = (data) => {
    editStudent(editId, data).then((res) => {
      setEditId(null);
    });
  };
  const searchStudents = () => {
    navigate({
      pathname: location.pathname,
      search: `?search=${searchQuery}`,
    });
  };

  const goToStudentDetails = (id) => {
    navigate(routes.studentDetails.getPath(id));
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        padding: 2,
        gap: 2,
      }}
    >
      <TextField
        autoFocus
        margin="dense"
        id="search"
        label="Search"
        fullWidth
        variant="standard"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            searchStudents();
          }
        }}
      />
      <TableX
        title="Students"
        action={<Button onClick={handleClickOpen}>Add</Button>}
        rows={students}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Details",
            icon: <InfoIcon fontSize="small" />,
            onClick: goToStudentDetails,
          },
          {
            title: "Edit",
            icon: <EditIcon fontSize="small" />,
            onClick: onEditStudent,
          },
        ]}
        noData={
          query
            ? "No Students found. Update filters and try again"
            : "Use the search bar to find students by name or email."
        }
      />

      <AddStudentForm
        open={addFormOpen}
        handleAdd={onAddStudent}
        handleClose={handleClose}
      />
      <EditStudentForm
        open={Boolean(editId)}
        obj={students.find((s) => s._id === editId)}
        handleSubmit={onEditSubmit}
        handleClose={() => setEditId(null)}
      />
    </Box>
  );
};

export default SuperAdminStudents;
