import { Box } from "@mui/material";
import React, { useState } from "react";
import NavBar from "./NavBar";
import Router from "./Router";
import Sidebar from "./Sidebar";

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleDrawer = () => setSidebarOpen(!sidebarOpen);

  return (
    <>
      <NavBar toggleDrawer={toggleDrawer} />
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          width: "100%",
          height: "86vh",
          marginTop: 0,
          paddingTop: 2,
          overflow: "auto",
          backgroundColor: "#f4faff",
        }}
      >
        <Router />
      </Box>
    </>
  );
}
