import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import * as React from "react";
import Account from "./Account";
import Logo from "./Logo";

export const MenuButton = ({ onClick }) => {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default function NavBar({ toggleDrawer }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{
          backgroundColor: "#23395b",
          color: "white",
        }}
      >
        <Toolbar>
          <MenuButton onClick={toggleDrawer} />
          <Logo />
          <Account />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
