import _ from "lodash";
import { useEffect, useState } from "react";
import { createCoupon, updateCoupon } from "../../api/superAdmin";
import DialogX from "../utils/Dialog";
import CouponForm from "./CouponForm";

const CouponCreate = ({ obj, open, handleCancel }) => {
  const [couponCode, setCouponCode] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [couponType, setCouponType] = useState(null);
  const [discount, setDiscount] = useState({});
  const [plan, setPlan] = useState(null);

  const editMode = Boolean(obj?._id);

  useEffect(() => {
    if (obj) {
      setCouponCode(obj.code);
      setSelectedUsers(obj.users.map((u) => u.user));
      setCouponType(obj.type);
      setDiscount({ flat: obj.flat, percentage: obj.percentage, emi: obj.emi });
      setPlan(obj.plan?._id);
    }
  }, [obj]);

  const onCreateSubmit = async () => {
    await createCoupon({
      code: couponCode,
      userIds: selectedUsers.map((u) => u._id),
      emi: discount.emi,
      flat: discount.flat,
      percentage: discount.percentage,
      type: couponType,
      planId: plan,
    });
    handleCancel();
  };

  const onEditSubmit = async () => {
    await updateCoupon(obj._id, {
      code: couponCode,
      userIds: selectedUsers.map((u) => u._id),
      emi: discount.emi,
      flat: discount.flat,
      percentage: discount.percentage,
      type: couponType,
      planId: plan,
    });
    handleCancel();
  };

  const handleDiscountChange = (key, value) => {
    setDiscount((prev) => {
      return _.set({ ...prev }, key, value);
    });
  };

  return (
    <DialogX
      open={open}
      handleClose={handleCancel}
      title={`${editMode ? "Update" : "Create"} Coupon`}
      content={
        <CouponForm
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          couponType={couponType}
          setCouponType={setCouponType}
          plan={plan}
          setPlan={setPlan}
          discount={discount}
          setDiscount={handleDiscountChange}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      }
      primaryAction={editMode ? onEditSubmit : onCreateSubmit}
      primaryActionTitle={editMode ? "Save" : "Create"}
    />
  );
};

export default CouponCreate;
