import React from "react";
import {
  Box,
  Divider,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const PreparationPlanDetails = ({ obj = {} }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        sx={(theme) => ({
          m: 1,
          width: "100%",
          mt: 2,
          [theme.breakpoints.down("md")]: {
            p: 1,
            m: 1,
          },
        })}
      >
        <Typography variant="h4" sx={{ m: 1 }}>
          {obj.title}
        </Typography>
        <Typography variant="body1" sx={{ m: 1 }}>
          {obj.description}
        </Typography>

        {obj.schedule?.map((dayItem, index) => (
          <Paper
            key={dayItem._id}
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: 1,
              mt: 3,
            }}
          >
            <Typography sx={{ p: 1, pl: 2 }} variant="h6">
              Day {index + 1}
            </Typography>
            <Divider />

            <List>
              {dayItem.subTopics.map((subTopic) => (
                <ListItem key={subTopic}>
                  <ListItemText primary={subTopic} />
                </ListItem>
              ))}
            </List>
            <Divider />
            <Box sx={{ p: 1, pl: 2, display: "flex", flexDirection: "row" }}>
              <Typography variant="body1" fontWeight="600">
                Mode:&nbsp;
              </Typography>
              <Typography textTransform="capitalize" variant="body1">
                {dayItem.mode}
              </Typography>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default PreparationPlanDetails;
