import { useEffect, useState } from "react";
import { getTestReport } from "../api/college";
import TableX from "./TableX";
import { convertToMap } from "../utils/object";
import { Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  testStatuses,
  testStatusesLabel,
  testUserStatuses,
} from "../utils/constants";
import DialogX from "./utils/Dialog";
import StudentTestReport from "./Reports/StudentTestReport";

const TestReport = ({ testId }) => {
  const [students, setStudents] = useState({});
  const [test, setTest] = useState({});
  const [scores, setScores] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showStudentReport, setShowStudentReport] = useState(null);

  useEffect(() => {
    setLoading(true);
    getTestReport(testId)
      .then((res) => {
        const { test, userTestScores, students } = res;
        setTest(test);
        setScores(convertToMap(userTestScores, "userId"));
        setStudents(convertToMap(students, "_id"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const rows = Object.values(students).map((student) => {
    const score = scores[student._id];
    return {
      ...student,
      score: score?.score,
      testStatus: score?.testStatus,
      userStatus: score?.userStatus,
    };
  });

  const columns = [
    { title: "Student Name", field: "name" },
    { title: "Email", field: "email" },
    {
      title: "Score",
      field: "score",
      render: (v) => `${v.score}/${v.total}`,
      onSort: (a, b) => a.score - b.score,
    },
    {
      title: "Status",
      field: "userStatus",
      render: (v) => testStatusesLabel[v],
    },
    {
      title: "Actions",
      field: "_id",
      render: (v, r) =>
        r.userStatus === testUserStatuses.SUBMITTED &&
        test.status === testStatuses.FINISHED ? (
          <Button variant="text" onClick={() => setShowStudentReport(r._id)}>
            Report
          </Button>
        ) : (
          <Typography variant="body1">-</Typography>
        ),
    },
  ];

  return (
    <div>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {test.title}
      </Typography>
      {test.startTime && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Date: {dayjs(test.startTime).format("DD MMM YYYY, hh:mm A")}
        </Typography>
      )}
      <DialogX
        title="Student Test Report"
        open={Boolean(showStudentReport)}
        content={
          <StudentTestReport testId={testId} studentId={showStudentReport} />
        }
        handleClose={() => setShowStudentReport(null)}
      />
      <TableX
        columns={columns}
        rows={rows}
        loading={loading}
        error={error}
        keyExtractor={(row) => row._id}
      />
    </div>
  );
};

export default TestReport;
