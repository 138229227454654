import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  SwipeableDrawer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { MenuButton } from "./NavBar";
import routes from "./routes";
import { getUserDetails } from "../api/user";
import AppsIcon from "@mui/icons-material/Apps";

const Sidebar = ({ open, toggleDrawer }) => {
  let navigate = useNavigate();
  const [user, setUser] = useState({});

  const pages = Object.values(routes).filter((route) => route.sidebar);
  const apps = [
    { title: "Study Material", url: "http://material.eh.com" },
    { title: "Test", url: "http://test.eh.com" },
  ];

  useEffect(() => {
    getUserDetails().then((res) => {
      setUser(res.user);
    });
  }, []);

  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Paper
        elevation={6}
        square
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 3,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <MenuButton onClick={toggleDrawer} />
        <Logo />
      </Paper>

      <Box
        sx={{ width: 280, padding: 1, paddingLeft: 0.5 }}
        role="presentation"
        onClick={toggleDrawer}
      >
        <ListItem>
          <a
            href={process.env.REACT_APP_AUTH_WEB}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Apps" />
            </ListItemButton>
          </a>
        </ListItem>
        <Divider />
        <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Pages</ListSubheader>}
        >
          {pages
            .filter((p) => p.for?.includes(user.role))
            .map(({ title, path }) => (
              <ListItem key={title} disablePadding>
                <ListItemButton onClick={() => navigate(path)}>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <Divider />

        {/* <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Apps</ListSubheader>}
        >
          {apps.map(({ title, url }, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItemText primary={title} />
                </a>
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Box>
    </SwipeableDrawer>
  );
};

export default Sidebar;
