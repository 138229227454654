import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import routes from "../layout/routes";

const QuickActionCard = ({ title, description, path }) => {
  let navigate = useNavigate();

  const goToPath = () => {
    navigate(path);
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        height: 50,
        p: 2,
        pt: 1,
        pb: 1,
        "&:hover": {
          cursor: "pointer",
          borderColor: "#aaa",
          backgroundColor: "#fafafa",
        },
      }}
      onClick={goToPath}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Typography
            variant="body2"
            component="p"
            color="text.secondary"
            gutterBottom
          >
            {description}
          </Typography>
        </Box>
        <Box>
          <IconButton
            sx={{ borderRadius: 25 }}
            aria-label="go"
            onClick={goToPath}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
};

export const ManageCertificates = () => {
  return (
    <QuickActionCard
      title="Manage Certificates"
      description="Add certificates"
      path={routes.certificates.path}
    />
  );
};

export const ManageStudents = () => {
  return (
    <QuickActionCard
      title="Manage Students"
      description="Add, edit Students"
      path={routes.students.path}
    />
  );
};

export const ManageStudentsForSuperAdmin = () => {
  return (
    <QuickActionCard
      title="Manage Students"
      description="Add, edit Students"
      path={routes.studentsSuperAdmin.path}
    />
  );
};

export const ManageCoupons = () => {
  return (
    <QuickActionCard
      title="Manage Coupons"
      description="Add, edit Coupons"
      path={routes.coupons.path}
    />
  );
};

export const ManagePreparationPlans = () => {
  return (
    <QuickActionCard
      title="Manage Preparation Plans"
      description="Add, edit preparation plans"
      path={routes.preparationPlan.path}
    />
  );
};

export const ManagePricingPlans = () => {
  return (
    <QuickActionCard
      title="Manage Pricing Plans"
      description="Add, edit pricing plans"
      path={routes.pricingPlan.path}
    />
  );
};

export const ManageAdmins = () => {
  return (
    <QuickActionCard
      title="Manage Admins"
      description="Add, edit, delete admins"
      path={routes.admins.path}
    />
  );
};

export const ManageColleges = () => {
  return (
    <QuickActionCard
      title="Manage Colleges"
      description="Add, edit, delete colleges"
      path={routes.colleges.path}
    />
  );
};

export const ManageCompanies = () => {
  return (
    <QuickActionCard
      title="Manage Companies"
      description="Add, edit, delete companies"
      path={routes.companies.path}
    />
  );
};

export const ViewReports = () => {
  return (
    <QuickActionCard
      title="Reports"
      description="Analyse students performance"
      path={routes.reports.path}
    />
  );
};

export const ViewNoticeBoard = () => {
  return (
    <QuickActionCard
      title="Notice Board"
      description="Announce, communicate with students"
      path={routes.noticeboard.path}
    />
  );
};

export const ManageTests = () => {
  return (
    <QuickActionCard
      title="Manage Tests"
      description="Create, edit Tests. View results"
      path={routes.tests.path}
    />
  );
};
