import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  FilterAlt as FilterIcon,
  MobiledataOff as MobiledataOffIcon,
} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useMemo, useRef, useState } from "react";
import { unique } from "../utils/object";
import Loader from "./Loader";
import SearchBar from "./SearchBar";

const EmptyTable = ({ span, message = "No data found" }) => {
  return (
    <TableRow>
      <TableCell sx={{ textAlign: "center" }} colSpan={span}>
        {message}
      </TableCell>
    </TableRow>
  );
};

const SortColumn = ({ order, onClick }) => {
  const items = [
    {
      key: "asc",
      title: "Ascending",
      Icon: ArrowUpwardIcon,
    },
    {
      key: "desc",
      title: "Descending",
      Icon: ArrowDownwardIcon,
    },
    {
      key: "none",
      title: "None",
      Icon: MobiledataOffIcon,
    },
  ];

  const currentIndex = !order ? 2 : items.findIndex((i) => i.key === order);
  const Icon = items[currentIndex].Icon;
  const nextIndex = (items.findIndex((i) => i.key === order) + 1) % 3;
  return (
    <IconButton
      size="small"
      sx={{ ml: -1 }}
      onClick={() => onClick(items[nextIndex].key)}
    >
      <Icon
        fontSize="small"
        color={currentIndex !== 2 ? "primary" : "disabled"}
      />
    </IconButton>
  );
};

const FilterColumn = ({ filters, selected, updateFilters }) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  console.log(filters, selected);

  const toggle = (value) => {
    if (selected?.includes(value)) {
      updateFilters(selected.filter((v) => v !== value));
    } else {
      updateFilters([...selected, value]);
    }
  };

  return (
    <>
      <IconButton size="small" sx={{ ml: 1 }} ref={anchorEl}>
        <FilterIcon onClick={() => setOpen(true)} />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl?.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {filters.map(({ title, value }, index) => {
          return (
            <MenuItem key={index} value={value} onClick={() => toggle(value)}>
              <Checkbox checked={selected.indexOf(value) > -1} />
              <ListItemText primary={title} />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#b9d4ffab",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableRowX = ({
  showIndexColumn,
  showActionsColumn,
  index,
  keyExtractor,
  row,
  columns,
  actions,
}) => {
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  return (
    <>
      <StyledTableRow hover role="checkbox" tabIndex={-1}>
        {showIndexColumn && (
          <StyledTableCell sx={{ textAlign: "center" }}>
            {index + 1}
          </StyledTableCell>
        )}
        {columns.map((column) => {
          const value = row[column.field];
          return (
            <StyledTableCell
              key={`${column.field}-${keyExtractor(row)}`}
              align={column.align || "left"}
              sx={column.getStyle ? column.getStyle(value) : {}}
            >
              {column.render ? column.render(value, row) : value}
            </StyledTableCell>
          );
        })}
        {showActionsColumn && (
          <StyledTableCell sx={{ textAlign: "center" }}>
            <IconButton
              aria-label="menu"
              onClick={() => setOpen(true)}
              ref={anchorEl}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl?.current}
              open={open}
              onClick={() => setOpen(false)}
              onClose={() => setOpen(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {actions.map(({ title, icon, onClick }) => {
                return (
                  <MenuItem
                    key={`${title}-${keyExtractor(row)}`}
                    onClick={() => onClick(row._id)}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{title}</ListItemText>
                  </MenuItem>
                );
              })}
            </Menu>
          </StyledTableCell>
        )}
      </StyledTableRow>
    </>
  );
};

const TableX = ({
  title,
  action,
  rows,
  columns,
  showSearch,
  showIndexColumn,
  keyExtractor,
  actions,
  loading,
  style = {},
  tableStyle = {},
  noData,
}) => {
  const showActionsColumn = actions && actions.length > 0;

  const [sort, setSort] = useState({ field: null, order: null, func: null });
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({});

  const colFilters = useMemo(() => {
    const filtersOfColumns = {};
    columns.forEach((column) => {
      if (column.filterValue) {
        filtersOfColumns[column.field] = unique(
          rows.map(column.filterValue),
          "value"
        );
      }
    });
    return filtersOfColumns;
  }, [rows, columns]);

  const filteredData = useMemo(() => {
    let filteredData = rows;
    if (search) {
      filteredData = filteredData.filter((row) =>
        columns.some((column) => {
          const value = row[column.field];
          return (
            value &&
            value.toString().toLowerCase().includes(search.toLowerCase())
          );
        })
      );
    }
    if (Object.keys(filters).length) {
      filteredData = filteredData.filter((row) =>
        Object.keys(filters)
          .filter((field) => filters[field]?.length > 0)
          .every((field) => {
            const column = columns.find((c) => c.field === field);
            const { value } = column.filterValue(row);
            return filters[field].includes(value);
          })
      );
    }
    return filteredData;
  }, [search, rows, columns, filters]);

  const finalData = useMemo(() => {
    if (!sort.field) {
      return filteredData;
    }
    const sortedData = [...filteredData].sort(sort.func);
    if (sort.order === "asc") {
      return sortedData;
    } else if (sort.order === "desc") {
      return sortedData.reverse();
    }
    return filteredData;
  }, [sort, filteredData]);

  const clearFilters = () => {
    setFilters({});
    setSearch("");
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        overflow: "hidden",
        ...style,
      }}
    >
      <TableContainer
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "80%",
          height: "100%",
          mr: "auto",
          ml: "auto",
          ...tableStyle,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{title}</Typography>
          {showSearch && <SearchBar value={search} onChange={setSearch} />}
          {action}
        </Box>
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader sx={{ width: "100%", height: "100%", mt: 1 }}>
            <TableHead>
              <TableRow>
                {showIndexColumn && (
                  <StyledTableCell
                    sx={{
                      textAlign: "center",
                      width: "5%",
                    }}
                  >
                    #
                  </StyledTableCell>
                )}
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align={column.align || "left"}
                    sx={{
                      width: column.width,
                    }}
                    // className={column.sticky ? "sticky-column" : ""}
                  >
                    {column.onSort && (
                      <SortColumn
                        order={
                          sort.field === column.field ? sort.order : "none"
                        }
                        onClick={(order) => {
                          setSort({
                            field: column.field,
                            order,
                            func: column.onSort,
                          });
                        }}
                      />
                    )}
                    {column.title}
                    {column.filterValue && (
                      <FilterColumn
                        filters={colFilters[column.field]}
                        selected={filters[column.field] || []}
                        updateFilters={(values) =>
                          setFilters({ ...filters, [column.field]: values })
                        }
                      />
                    )}
                  </StyledTableCell>
                ))}
                {showActionsColumn && (
                  <StyledTableCell
                    sx={{
                      textAlign: "center",
                      width: "1%",
                    }}
                  >
                    Actions
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!rows?.length ? (
                <EmptyTable
                  span={
                    columns.length +
                    (showIndexColumn ? 1 : 0) +
                    (showActionsColumn ? 1 : 0)
                  }
                  message={noData}
                />
              ) : !finalData?.length ? (
                <EmptyTable
                  span={columns.length}
                  message={
                    <Typography variant="button">
                      No data for the filters applied.
                      <Button variant="text" onClick={clearFilters}>
                        Clear filters
                      </Button>
                    </Typography>
                  }
                />
              ) : (
                finalData.map((row, index) => {
                  return (
                    <TableRowX
                      key={keyExtractor(row)}
                      keyExtractor={keyExtractor}
                      index={index}
                      row={row}
                      columns={columns}
                      actions={actions}
                      showActionsColumn={showActionsColumn}
                      showIndexColumn={showIndexColumn}
                    />
                  );
                })
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default TableX;
