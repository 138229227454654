import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { getDate } from "../utils/date";
import Empty from "./Empty";

const NumberInfoCard = ({ title, value, action, style = {} }) => {
  return (
    <Paper
      variant="outlined"
      className="student-info-container info-card-number"
      style={{ ...style }}
    >
      <Typography variant="h2">{value}</Typography>
      <Box>
        <Typography variant="button" gutterBottom>
          {title}
        </Typography>
        {action}
      </Box>
    </Paper>
  );
};

const ListInfoCard = ({ title, items, style = {}, onClick }) => {
  return (
    <Paper
      variant="outlined"
      className="student-info-container info-card-list"
      style={{ ...style }}
    >
      {title}
      {items.length === 0 ? (
        <Empty />
      ) : (
        <List sx={{ overflow: "auto" }}>
          {items.map((item) => (
            <React.Fragment key={item._id}>
              <ListItemButton onClick={() => onClick(item)}>
                <ListItemAvatar>
                  <Avatar
                    alt={item.name}
                    src={item.avatar}
                    sx={{ bgcolor: "#474848" }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={`Last seen: ${
                    item?.activity?.lastActive
                      ? getDate(item.activity.lastActive)
                      : "Never"
                  }`}
                />
              </ListItemButton>
              <Divider variant="inset" />
            </React.Fragment>
          ))}
        </List>
      )}
    </Paper>
  );
};

const InfoCard = {
  Number: NumberInfoCard,
  List: ListInfoCard,
};

export default InfoCard;
