import { Box, Grid, Typography } from "@mui/material";
import {
  ManageAdmins,
  ManageCertificates,
  ManageColleges,
  ManageCompanies,
  ManageCoupons,
  ManagePreparationPlans,
  ManagePricingPlans,
  ManageStudents,
  ManageStudentsForSuperAdmin,
  ManageTests,
  ViewNoticeBoard,
  ViewReports,
} from "../components/QuickActions";
import withAuth from "../hoc/withAuth";

const Home = ({ isSuperAdmin, isStaffAdmin, isAdmin }) => {
  let quickActions = [];
  if (isSuperAdmin) {
    quickActions = [
      <ManageColleges />,
      <ManageCompanies />,
      <ManageCertificates />,
      <ManageStudentsForSuperAdmin />,
      <ManagePreparationPlans />,
      <ManagePricingPlans />,
      <ManageCoupons />,
    ];
  } else if (isStaffAdmin) {
    quickActions = [
      <ManageColleges />,
      <ManageCertificates />,
      <ManageStudentsForSuperAdmin />,
      <ManageCoupons />,
    ];
  } else if (isAdmin) {
    quickActions = [
      <ManageStudents />,
      <ViewReports />,
      <ViewNoticeBoard />,
      <ManageTests />,
      <ManageAdmins />,
    ];
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, padding: 2 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Quick Actions
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        flexWrap="wrap"
        spacing={2}
      >
        {quickActions.map((action) => (
          <Grid item xs={12} sm={6} md={4} key={action.key}>
            {action}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default withAuth(Home);
