import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Paper, Skeleton, Tab } from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useStudent } from "../api/college";
import StudentActivity from "../components/StudentActivity";
import StudentInfo from "../components/StudentInfo";
import StudentProgress from "../components/StudentProgress";
import StudentTests from "../components/StudentTests";
import StudentTransactions from "../components/StudentTransactions";
import withAuth from "../hoc/withAuth";
import routes from "../layout/routes";

const StudentDetails = ({ user, isSuperAdmin, isStaffAdmin }) => {
  const [selectedTab, setSelectedTab] = useState("tests");

  const { id } = useParams();
  const navigate = useNavigate();

  const { student, isLoading, error } = useStudent(user, id);

  return (
    <Box
      sx={(theme) => ({
        width: "65%",
        mx: "auto",
        [theme.breakpoints.down("md")]: {
          width: "95%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "98%",
        },
      })}
    >
      <Button
        variant="outlined"
        sx={{
          textTransform: "none",
          color: "#444",
          borderColor: "#ccc",
          ":hover": {
            backgroundColor: "#eee",
            borderColor: "#aaa",
          },
        }}
        startIcon={<KeyboardBackspaceIcon />}
        onClick={() => navigate(routes.students.path)}
      >
        All Students
      </Button>
      <h1>Student Details</h1>
      {isLoading ? (
        <>
          <Paper variant="outlined" className="student-info-container">
            <Skeleton height={12} />
            <Skeleton height={40} animation="wave" />
          </Paper>
          <Paper variant="outlined" className="student-info-container">
            <Skeleton height={12} />
            <Skeleton height={40} animation="wave" />
          </Paper>
        </>
      ) : (
        <>
          <StudentInfo data={student.details} />
          <StudentActivity data={student.activity} />
          <StudentProgress data={student.progress} />
        </>
      )}
      {isSuperAdmin || isStaffAdmin ? (
        <TabContext value={selectedTab}>
          <TabList onChange={(_, newTab) => setSelectedTab(newTab)}>
            <Tab label="Tests" value="tests" />
            <Tab label="Transactions" value="transactions" />
          </TabList>
          <Box sx={{ overflowY: "auto" }}>
            <TabPanel value="tests">
              <StudentTests studentId={id} />
            </TabPanel>
            <TabPanel value="transactions">
              <StudentTransactions studentId={id} />
            </TabPanel>
          </Box>
        </TabContext>
      ) : (
        <StudentTests studentId={id} />
      )}
    </Box>
  );
};

export default withAuth(StudentDetails);
