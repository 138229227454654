import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { getPricingPlans, getStudents } from "../../api/superAdmin";
import { COUPON_TYPES } from "../../utils/constants";
import SearchBar from "../SearchBar";

const CouponForm = ({
  couponCode,
  setCouponCode,
  couponType,
  setCouponType,
  selectedUsers,
  setSelectedUsers,
  plan,
  setPlan,
  discount,
  setDiscount,
}) => {
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    getPricingPlans().then((res) => {
      setPlans(res.plans);
    });
  }, []);

  const searchStudents = () => {
    getStudents({ search }).then((res) => setStudents(res.students));
  };

  const removeUser = (userId) => {
    setSelectedUsers((prev) => prev.filter((u) => u._id !== userId));
  };

  const selectedUserIds = new Set(selectedUsers.map((u) => u._id));

  const handleToggle = (userId, user) => () => {
    if (selectedUserIds.has(userId)) {
      setSelectedUsers((prev) => prev.filter((u) => u._id !== userId));
    } else {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  const {
    emi = [{ amount: 0, duration: 0 }],
    flat = { amount: 0 },
    percentage = { percentage: 0, maxDiscount: 0 },
  } = discount;

  const selectedPlan = useMemo(
    () => plans.find((p) => p._id === plan),
    [plan, plans]
  );

  const remainingEMIAmount = useMemo(
    () =>
      (selectedPlan?.discountedAmount || selectedPlan?.amount || 0) -
      emi.reduce((a, b) => a + b.amount, 0),
    [discount, selectedPlan]
  );

  return (
    <Box>
      <Paper variant="outlined" className="section">
        <Typography variant="button">Coupon code:</Typography>
        <TextField
          autoFocus
          margin="dense"
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
        />

        <FormControl>
          <FormLabel>Plan</FormLabel>
          <RadioGroup
            row
            value={plan}
            onChange={(e) => setPlan(e.target.value)}
          >
            {plans.map((plan) => (
              <FormControlLabel
                key={plan._id}
                value={plan._id}
                control={<Radio />}
                label={plan.name}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel>Coupon Type</FormLabel>
          <RadioGroup
            row
            value={couponType}
            onChange={(e) => setCouponType(e.target.value)}
          >
            {Object.keys(COUPON_TYPES).map((key) => (
              <FormControlLabel
                className="title-case"
                key={key}
                value={COUPON_TYPES[key]}
                control={<Radio />}
                label={COUPON_TYPES[key]}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {couponType && <Typography variant="body1">Coupon Options</Typography>}
        {couponType === COUPON_TYPES.EMI ? (
          <>
            {emi.map((item, index) => (
              <Paper
                variant="outlined"
                className="section"
                sx={{ backgroundColor: "#efefef" }}
              >
                <TextField
                  margin="dense"
                  label="Amount"
                  type="number"
                  min={1}
                  value={item.amount}
                  onChange={(e) =>
                    setDiscount(
                      ["emi", index, "amount"],
                      Number(e.target.value)
                    )
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"> &#8377;</InputAdornment>
                    ),
                  }}
                  sx={{ backgroundColor: "white" }}
                />
                {index < emi.length - 1 ? (
                  <TextField
                    margin="dense"
                    label="Number of days for next payment"
                    type="number"
                    min={1}
                    value={item.duration}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">Days</InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      setDiscount(
                        ["emi", index, "duration"],
                        Number(e.target.value)
                      )
                    }
                    sx={{ backgroundColor: "white" }}
                  />
                ) : (
                  <Typography variant="body2">
                    This amount will provide validity for the remaining days of
                    the plan
                  </Typography>
                )}
              </Paper>
            ))}

            <Typography
              variant="body2"
              color={remainingEMIAmount >= 0 ? "warning" : "error"}
            >
              Remaining amount: {remainingEMIAmount}
            </Typography>

            <Button
              onClick={() =>
                setDiscount("emi", [...emi, { amount: 0, duration: 1 }])
              }
            >
              Add EMI
            </Button>
          </>
        ) : couponType === COUPON_TYPES.FLAT ? (
          <TextField
            margin="dense"
            label="Amount"
            type="number"
            value={flat.amount}
            onChange={(e) => setDiscount("flat.amount", e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"> &#8377;</InputAdornment>
              ),
            }}
          />
        ) : couponType === COUPON_TYPES.PERCENTAGE ? (
          <>
            <TextField
              margin="dense"
              label="Percentage"
              type="number"
              value={percentage.percentage}
              onChange={(e) =>
                setDiscount("percentage.percentage", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              label="Maximum discount"
              type="number"
              value={percentage.maxDiscount}
              onChange={(e) => setDiscount("maxDiscount", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> &#8377;</InputAdornment>
                ),
              }}
            />
          </>
        ) : null}
      </Paper>

      <Paper variant="outlined" className="section">
        <Typography variant="button">Selected Users:</Typography>
        <Box>
          {selectedUsers.map((user) => (
            <Chip
              label={user.email}
              variant="outlined"
              onDelete={() => removeUser(user._id)}
            />
          ))}
        </Box>
      </Paper>
      <Paper variant="outlined" className="section">
        <Typography variant="button">Search users:</Typography>
        <SearchBar
          value={search}
          onChange={setSearch}
          onSubmit={searchStudents}
        />

        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {students.map((student) => {
            return (
              <ListItem key={student._id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(student._id, student)}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedUserIds.has(student._id)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={student.name}
                    secondary={student.email}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
};

export default CouponForm;
