import { useContext } from "react";
import { AuthContext } from "../App";

const withAuth = (Component) => {
  return (props) => {
    const {
      state: { isAuthenticated, user },
      dispatch,
    } = useContext(AuthContext);
    const isSuperAdmin = isAuthenticated && user?.role === "superadmin";
    const isStaffAdmin = isAuthenticated && user?.role === "staffadmin";
    const isAdmin = isAuthenticated && user?.role === "admin";

    return (
      <Component
        {...props}
        dispatch={dispatch}
        isAuthenticated={isAuthenticated}
        user={user}
        isAdmin={isAdmin}
        isSuperAdmin={isSuperAdmin}
        isStaffAdmin={isStaffAdmin}
      />
    );
  };
};

export default withAuth;
