import { useMemo, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Divider,
  createTheme,
  ThemeProvider,
  Box,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import { testStatuses } from "../utils/constants";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ConfirmDialog from "./utils/ConfirmDialog";
import { useNavigate } from "react-router";
import routes from "../layout/routes";

const theme = createTheme({
  palette: {
    dark: {
      main: "#333",
      contrastText: "#fff",
    },
  },
});

export const TestSectionsTimeline = ({
  obj,
  compressed = false,
  onViewAll,
}) => {
  const sections = compressed ? obj.sections.slice(0, 2) : obj.sections;
  return (
    <Timeline sx={{ pl: 0, pt: 0, mt: 0, height: 120 }}>
      {sections.map((section, index) => (
        <TimelineItem
          sx={{
            ":before": {
              paddingLeft: 0,
              marginLeft: 0,
              flex: 0,
            },
          }}
          key={index}
        >
          <TimelineSeparator>
            <TimelineDot />
            {index === obj.sections.length - 1 ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1" gutterBottom component="div">
              {section.title}
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
              Duration: {section.duration} minutes
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
      {obj.sections.length > sections.length && (
        <ThemeProvider theme={theme}>
          <Button
            variant="outlined"
            size="small"
            color="dark"
            onClick={onViewAll}
          >
            View All
          </Button>
        </ThemeProvider>
      )}
    </Timeline>
  );
};

export const TestCardActions = ({ obj, showReport }) => {
  return obj.status === testStatuses.FINISHED ? (
    <Button size="small" endIcon={<ArrowForwardIcon />} onClick={showReport}>
      Show Report
    </Button>
  ) : obj.status === testStatuses.IN_PROGRESS ? (
    <Typography
      variant="button"
      sx={{ pl: 1, pr: 1, display: "flex", alignItems: "center" }}
      color="error"
    >
      <RadioButtonCheckedIcon sx={{ mr: 1 }} fontSize="small" />
      Test is in progress
    </Typography>
  ) : obj.status === testStatuses.SCHEDULED ? (
    <Typography
      variant="button"
      sx={{ pl: 1, pr: 1, display: "flex", alignItems: "center" }}
    >
      <HistoryToggleOffIcon sx={{ mr: 1 }} fontSize="small" />
      Starts on {dayjs(obj.startTime).format("MMM DD, YYYY hh:mm A")}
    </Typography>
  ) : obj.status === testStatuses.CANCELLED ? (
    <Typography
      variant="button"
      sx={{ pl: 1, pr: 1, display: "flex", alignItems: "center" }}
      color="grey"
    >
      <CancelIcon sx={{ mr: 1 }} fontSize="small" />
      Cancelled
    </Typography>
  ) : null;
};

const TestCard = ({ obj, onDelete, onExpand, onShowReport }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const handleDelete = () => {
    setConfirmDialogOpen(true);
  };

  const onEdit = () => {
    setMenuOpen(false);
    navigate(routes.editTest.getPath(obj._id));
  };

  const confirmDialogMessage = useMemo(() => {
    return `Are you sure you want to delete test ${
      obj.title
    } scheduled on ${dayjs(obj.startTime).format("MMM DD, YYYY hh:mm A")}?`;
  }, [obj]);

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        onCloseConfirm={() => setConfirmDialogOpen(false)}
        onConfirm={onDelete}
        title="Delete Test"
        message={confirmDialogMessage}
      />
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "fit-content",
          height: "auto",
          overflow: "visible",
          m: 1,
        }}
        variant="outlined"
      >
        <CardContent sx={{ height: 260, pt: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <Typography variant="h5" component="div">
              {obj.title}
            </Typography>
            {obj.status === testStatuses.SCHEDULED && (
              <IconButton
                ref={anchorRef}
                aria-label="more"
                id="long-button"
                aria-controls={menuOpen ? "long-menu" : undefined}
                aria-expanded={menuOpen ? "true" : undefined}
                aria-haspopup="true"
                onClick={() => setMenuOpen(true)}
                sx={{ mr: -1 }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
          <Divider />
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={obj} compressed onViewAll={onExpand} />
        </CardContent>
        <CardActions sx={{ marginTop: "auto", height: "auto" }}>
          <TestCardActions obj={obj} showReport={onShowReport} />
        </CardActions>
      </Card>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorRef?.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <MenuItem onClick={onEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TestCard;
