import { Box, colors, Paper, Typography, Alert } from "@mui/material";
import dayjs from "dayjs";
import CorrectIcon from "@mui/icons-material/Check";
import WrongIcon from "@mui/icons-material/Close";
import { LinearProgressWithLabel } from "../utils/Progress";

const TextQuestionReport = ({
  index,
  obj,
  responses,
  report,
  timeTaken,
  hideQueAnalytics,
}) => {
  const finalResponse = responses?.sort((a, b) =>
    dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1
  )[0]; // sorted by createdAt with most recent submission at index 0

  const answered = !!finalResponse;

  const totalResponsesCount = report.correctCount + report.wrongCount;

  const correctOption = obj.options.find((option) => option.isCorrect)._id;
  const selectedOption = finalResponse?.optionId;

  return (
    <Paper
      sx={{
        mb: 2,
        p: 2,
        display: "flex",
        backgroundColor: !selectedOption
          ? colors.yellow[50]
          : correctOption === selectedOption
          ? colors.green[50]
          : colors.red[100],
      }}
      variant="outlined"
    >
      <Typography
        variant="h6"
        component="div"
        sx={{ lineHeight: 1.5, fontWeight: "400", fontSize: 18 }}
      >
        {index + 1}.
      </Typography>
      <Box sx={{ ml: 1, width: "100%" }}>
        {/* <Typography
          variant="h6"
          component="div"
          sx={{ lineHeight: 1.5, fontWeight: "400", fontSize: 18 }}
        >
          {obj.text}
        </Typography> */}
        <div
          style={{ height: "auto", width: "100%" }}
          dangerouslySetInnerHTML={{ __html: obj.text }}
        />
        <Box sx={{ mt: 1, width: "100%" }}>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ fontWeight: "bold" }}
          >
            Options:
          </Typography>
          <ol>
            {obj.options.map((option, index) => {
              const isCorrect = option.isCorrect;
              const isSelected = finalResponse?.optionId === option._id;

              const correct = answered && isSelected && isCorrect;
              const wrong = answered && isSelected && !isCorrect;

              console.log(
                report.options.find((o) => o.optionId === option._id)
                  .selectedCount
              );
              return (
                <li>
                  <Box
                    key={option._id}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ml: 2,
                      p: 1,
                      width: "90%",
                      borderRadius: 2,
                      backgroundColor: correct
                        ? colors.green[200]
                        : wrong
                        ? colors.red[200]
                        : "transparent",
                      borderWidth: isCorrect || isSelected ? 2 : 0,
                      borderStyle: "solid",
                      borderColor:
                        isCorrect && !answered
                          ? colors.amber[400]
                          : isCorrect && answered && !correct
                          ? colors.green[400]
                          : correct
                          ? colors.green[400]
                          : wrong
                          ? colors.red[400]
                          : null,
                    }}
                  >
                    <div
                      style={{ height: "auto", width: "auto" }}
                      dangerouslySetInnerHTML={{ __html: option.text }}
                    />
                    <Box
                      sx={{ ml: "auto", alignItems: "center", display: "flex" }}
                    >
                      {!answered && isCorrect && (
                        <>
                          <CorrectIcon
                            sx={{ ml: 1, mr: 1, color: colors.yellow[900] }}
                            fontSize="small"
                          />
                          Not attempted
                        </>
                      )}
                      {answered && isCorrect && !correct && (
                        <>
                          <CorrectIcon
                            sx={{ ml: 1, mr: 1 }}
                            color={"success"}
                            fontSize="small"
                          />
                          Correct Answer
                        </>
                      )}
                      {correct && (
                        <>
                          <CorrectIcon
                            sx={{ ml: 1, mr: 1 }}
                            color={"success"}
                            fontSize="small"
                          />
                          Given Answer
                        </>
                      )}
                      {wrong && (
                        <>
                          <WrongIcon
                            sx={{ ml: 1, mr: 1 }}
                            color={"error"}
                            fontSize="small"
                          />
                          Given Answer
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      p: 1,
                      pt: 0,
                      display: hideQueAnalytics ? "none" : "block",
                    }}
                  >
                    <LinearProgressWithLabel
                      value={
                        totalResponsesCount <= 0
                          ? 0
                          : (report.options.find(
                              (o) => o.optionId === option._id
                            ).selectedCount /
                              totalResponsesCount) *
                            100
                      }
                      label={" selected this option"}
                    />
                  </Box>
                </li>
              );
            })}
          </ol>
        </Box>
        <Box sx={{ width: "100%" }}>
          {timeTaken && !isNaN(timeTaken) && (
            <Alert icon={false} severity="info">
              Spent {parseInt(timeTaken / 1000)} seconds on this question.
            </Alert>
          )}
          <Typography sx={{ display: hideQueAnalytics ? "none" : "block" }}>
            {totalResponsesCount > 0
              ? (report.correctCount / totalResponsesCount) * 100
              : 0}
            {"% "} of students answered this question correct
          </Typography>
          <Typography sx={{ display: hideQueAnalytics ? "none" : "block" }}>
            {totalResponsesCount > 0
              ? (report.wrongCount / totalResponsesCount) * 100
              : 0}
            {"% "} of students answered this question wrong
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default TextQuestionReport;
