import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "./routes";

const Logo = () => {
  const navigate = useNavigate();

  return (
    <ButtonBase
      sx={{
        padding: 1,
        paddingLeft: 2,
        paddingRight: 2,
        borderRadius: 1.5,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        ml: "auto",
      }}
      onClick={() => navigate(routes.home.path)}
    >
      <div
        style={{
          background: "white",
          padding: "6px",
          borderRadius: "25px",
          height: "24px",
          width: "24px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <img src="/logo192.png" alt="logo" height={20} width={20} />
      </div>
      <Typography variant="h6" component="div" sx={{ ml: 1.2 }}>
        Admin
      </Typography>
    </ButtonBase>
  );
};

export default Logo;
