import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  addStudent,
  deleteStudent,
  editStudent,
  useStudents,
} from "../api/college";
import {
  AddStudentForm,
  EditStudentForm,
} from "../components/CollegeAdminStudentForm";
import InfoCard from "../components/InfoCard";
import TableX from "../components/TableX";
import DialogX from "../components/utils/Dialog";
import routes from "../layout/routes";
import { getDate, isToday } from "../utils/date";

const sortByActivity = (a, b) => {
  // returns in the order of most recent activity to least recent activity
  const aDate = a?.activity?.lastActive ? dayjs(a.activity.lastActive) : null;
  const bDate = b?.activity?.lastActive ? dayjs(b.activity.lastActive) : null;
  if (!aDate) return 1;
  if (!bDate) return -1;
  return aDate > bDate ? -1 : 1;
};

const columns = [
  {
    field: "name",
    title: "Name",
    width: "20%",
    onSort: (a, b) => {
      if (!a?.name) return 1;
      if (!b?.name) return -1;
      return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    },
    render: (_, row) => <Link to={`/students/${row._id}`}>{row.name}</Link>,
  },
  { field: "email", title: "E-mail", width: "20%" },
  {
    field: "branch",
    title: "Branch",
    width: "10%",
    render: (value) => value?.title,
    filterValue: (row) => ({
      title: row?.branch?.title,
      value: row?.branch?._id,
    }),
  },
  {
    field: "activity",
    title: "Last Active",
    width: "10%",
    onSort: sortByActivity,
    render: (value) => (value?.lastActive ? getDate(value.lastActive) : "-"),
  },
];

const Students = () => {
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showActiveToday, setShowActiveToday] = useState(false);

  const { students, mutate, isLoading } = useStudents();

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    setAddFormOpen(false);
  };

  const onAddStudent = async (data) => {
    const res = await addStudent(data);
    mutate([...students, res.student]);
  };
  const onEditStudent = (id) => {
    setEditId(id);
  };
  const onEditSubmit = (data) => {
    editStudent(editId, data).then((res) => {
      mutate(
        students.map((student) =>
          student._id === editId ? res.student : student
        )
      );
    });
  };
  const onDeleteStudent = (id) => {
    deleteStudent(id).then((res) => {
      mutate(students.filter((student) => student._id !== id));
    });
  };

  const goToStudentDetails = (id) => {
    navigate(routes.studentDetails.path.replace(":id", id));
  };

  const [mostActive, leastActive, activeToday] = useMemo(() => {
    const studentsByActive = [...students].sort(sortByActivity);
    const mostActive = studentsByActive
      .slice(0, 5)
      .filter((s) => s?.activity?.lastActive);
    const leastActive = studentsByActive
      .slice(mostActive.length)
      .slice(-5)
      .reverse();
    const activeToday = mostActive.filter((s) =>
      isToday(s.activity.lastActive)
    );
    return [mostActive, leastActive, activeToday];
  }, [students]);

  return (
    <Box
      sx={(theme) => ({
        width: "80%",
        mx: "auto",
        [theme.breakpoints.down("md")]: {
          width: "95%",
        },
        [theme.breakpoints.down("sm")]: {
          width: "98%",
        },
      })}
    >
      <DialogX
        open={showActiveToday}
        handleClose={() => setShowActiveToday(false)}
        title="Active Today"
        maxWidth="sm"
        content={
          <List>
            {activeToday.map((s) => (
              <ListItem key={s._id}>
                <ListItemButton onClick={() => goToStudentDetails(s._id)}>
                  <ListItemAvatar>
                    <Avatar alt={s.name} src={s.avatar} />
                  </ListItemAvatar>
                  <ListItemText primary={s.name} />

                  <ArrowForwardIcon />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        }
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "row",
            },
          })}
        >
          <InfoCard.Number
            title="Total Students"
            value={students.length}
            style={{ flex: 1, color: "#3D4E66" }}
          />
          <InfoCard.Number
            title="Active today"
            value={
              students.filter((s) =>
                s.activity?.lastActive ? isToday(s.activity.lastActive) : false
              ).length
            }
            action={
              <IconButton
                disabled={activeToday.length === 0}
                onClick={() => setShowActiveToday(true)}
                title="View"
              >
                <VisibilityIcon />
              </IconButton>
            }
            style={{ flex: 1, color: "#3D4E66" }}
          />
        </Box>
        <InfoCard.List
          title={
            <Typography variant="button" color="red" gutterBottom>
              Least Active
            </Typography>
          }
          style={{
            flex: 2,
            borderColor: "#FF5964",
            backgroundColor: "#FF59641F",
          }}
          items={leastActive}
          onClick={(item) => goToStudentDetails(item._id)}
        />
        <InfoCard.List
          style={{
            flex: 2,
            borderColor: "#61988E",
            backgroundColor: "#61988E1F",
          }}
          title={
            <Typography variant="button" color="green" gutterBottom>
              Most Active
            </Typography>
          }
          items={mostActive}
          onClick={(item) => goToStudentDetails(item._id)}
        />
      </Box>
      <Paper variant="outlined" sx={{ m: 1, mt: 2 }}>
        <TableX
          title="Students"
          loading={isLoading}
          showSearch
          style={{ width: "auto" }}
          tableStyle={{ width: "100%" }}
          action={
            <Button variant="outlined" onClick={handleClickOpen}>
              Add
            </Button>
          }
          rows={students}
          columns={columns}
          showIndexColumn
          keyExtractor={(r) => r._id}
          actions={[
            {
              title: "Details",
              icon: <InfoIcon fontSize="small" />,
              onClick: goToStudentDetails,
            },
            {
              title: "Edit",
              icon: <EditIcon fontSize="small" />,
              onClick: onEditStudent,
            },
            {
              title: "Delete",
              icon: <DeleteIcon fontSize="small" color="error" />,
              onClick: onDeleteStudent,
            },
          ]}
        />
      </Paper>
      {addFormOpen && (
        <AddStudentForm
          open={addFormOpen}
          handleAdd={onAddStudent}
          handleClose={handleClose}
        />
      )}
      {Boolean(editId) && (
        <EditStudentForm
          open={Boolean(editId)}
          obj={students.find((s) => s._id === editId)}
          handleSubmit={onEditSubmit}
          handleClose={() => setEditId(null)}
        />
      )}
    </Box>
  );
};

export default Students;
