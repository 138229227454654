import axios from "axios";
import { USER_ROLES } from "../utils/constants";
import { redirect } from "./utils";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_ADMIN_BACKEND_URL;

export const getBasePath = (userRole) => {
  console.log(userRole);
  let basePath = "/admin";
  switch (userRole) {
    case USER_ROLES.COLLEGE_ADMIN:
      basePath = "/admin/college";
      break;
    case USER_ROLES.SUPERADMIN:
      basePath = "/superadmin";
      break;
    case USER_ROLES.STAFFADMIN:
      basePath = "/superadmin";
      break;
    default:
      break;
  }
  return basePath;
};

export const postReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.post(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const postFileReq = async (url, body, auth, file) => {
  const data = new FormData();

  if (body) {
    const json = JSON.stringify(body);
    const blob = new Blob([json], {
      type: "application/json",
    });
    data.append("document", blob);
  }

  data.append("file", file, file.name);

  const headers = { "Content-Type": "multipart/form-data" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  try {
    let response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const putReq = async (url, body, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.put(url, body, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const getReq = async (url, params, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  // const params = new URLSearchParams(queryParams);
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.get(url, { params, headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const delReq = async (url, auth) => {
  const headers = { "Content-Type": "application/json" };
  const token = localStorage.getItem("token");
  if (auth) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  try {
    let response = await axios.delete(url, { headers });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      console.log(error.response.data);
      redirect(error.response.data.redirectUrl);
    } else {
      throw error;
    }
  }
};

export const uploadToBucket = async (
  url,
  file,
  filetype = "application/octet-stream"
) => {
  const headers = {
    "Content-Type": filetype,
    "Access-Control-Allow-Origin": "*",
  };
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers,
      body: file,
    });
    return response;
  } catch (error) {
    console.error({ error });
    throw error;
  }
};
