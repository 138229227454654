import React, { useRef, useState } from "react";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import withAuth from "../hoc/withAuth";

const Account = ({ isAuthenticated }) => {
  const profileRef = useRef(null);
  const [profileMenuIsVisible, setProfileMenuIsVisible] = useState(false);

  const showProfileMenu = () => setProfileMenuIsVisible(true);
  const hideProfileMenu = () => setProfileMenuIsVisible(false);

  const goToProfilePage = () => {
    window.location = process.env.REACT_APP_AUTH_WEB + "/profile";
  };
  const goToLoginPage = () => {
    window.location = process.env.REACT_APP_AUTH_WEB + "/login";
  };
  const performLogout = () => {
    sessionStorage.clear();
    window.location = process.env.REACT_APP_AUTH_WEB + "/logout";
  };

  return (
    <div style={{ marginLeft: "auto" }}>
      {isAuthenticated ? (
        <>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={showProfileMenu}
            ref={profileRef}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={profileRef}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={profileMenuIsVisible}
            onClose={hideProfileMenu}
          >
            <MenuItem onClick={goToProfilePage}>
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={performLogout}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Button color="inherit" onClick={goToLoginPage}>
          Login
        </Button>
      )}
    </div>
  );
};

export default withAuth(Account);
