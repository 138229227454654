import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Tab,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getTestReportOfStudent } from "../../api/college";
import withAuth from "../../hoc/withAuth";
import { testTypes } from "../../utils/constants";
import { convertToMap, groupBy } from "../../utils/object";
import { calculateScore } from "../../utils/test";
import TestSectionReport from "./TestSectionReport";

const StudentTestReport = ({ user, studentId, testId }) => {
  const [responses, setResponses] = useState({});
  const [test, setTest] = useState(null);
  const [report, setReport] = useState({});
  const [currentSection, setCurrentSection] = useState(null);
  const [timeAnalytics, setTimeAnalytics] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (studentId && testId) getReport();
  }, [studentId, testId]);

  const getReport = async () => {
    setLoading(true);
    try {
      const { test, responses, report, analytics } =
        await getTestReportOfStudent(user, { studentId, testId });
      setTest(test);
      setResponses(groupBy(responses, "questionId"));
      setTimeAnalytics(convertToMap(analytics, "questionId"));
      setReport(convertToMap(report, "questionId"));
      setCurrentSection(test.sections[0]._id);
    } catch (err) {
      console.log({ err });
      setError(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  const changeSection = (event, value) => {
    setCurrentSection(value);
  };

  return loading ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : error ? (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {error}
    </Alert>
  ) : (
    <Box sx={{ width: "100%", ml: "auto", mr: "auto" }}>
      <Typography variant="h6" component="div">
        Score: {calculateScore(test.sections, responses)}
      </Typography>
      <TabContext value={currentSection}>
        <TabList onChange={changeSection} aria-label="Test sections tabs">
          {test.sections?.map((section, index) => (
            <Tab
              key={section._id}
              label={`Section ${index + 1} - ${section.title}`}
              value={section._id}
            />
          ))}
        </TabList>
        <Box sx={{ overflowY: "auto" }}>
          {test.sections?.map((section) => (
            <TabPanel key={section._id} value={section._id}>
              <TestSectionReport
                hideQueAnalytics={test.testType === testTypes.PRACTICE}
                section={section}
                responses={responses}
                report={report}
                timeAnalytics={timeAnalytics}
              />
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

export default withAuth(StudentTestReport);
