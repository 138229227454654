import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getAllCertificates, getCertificate } from "../api/certificate";
import AddCertificateForm from "../components/CertificateForm";
import TableX from "../components/TableX";
import { DownloadOutlined as DownloadIcon } from "@mui/icons-material";

const columns = [
  { field: "_id", title: "ID", width: "20%" },
  { field: "userEmail", title: "e-mail", width: "20%" },
];

const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [addFormOpen, setAddFormOpen] = useState(false);

  useEffect(() => {
    fetchCertificates();
  }, []);

  const fetchCertificates = async () => {
    const res = await getAllCertificates();
    setCertificates(res.certificates);
  };

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    fetchCertificates();
    setAddFormOpen(false);
  };

  const onDownload = async (certificateId) => {
    const res = await getCertificate(certificateId);
    const url = res.certificateUrl;
    window.open(url, "_blank");
  };

  return (
    <>
      <TableX
        title="Certificates"
        action={<Button onClick={handleClickOpen}>Add</Button>}
        rows={certificates}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Download",
            icon: <DownloadIcon fontSize="small" />,
            onClick: onDownload,
          },
        ]}
      />
      <AddCertificateForm open={addFormOpen} handleClose={handleClose} />
    </>
  );
};

export default Certificates;
