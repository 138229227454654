import { COUPON_TYPES } from "../utils/constants";

export const getDiscount = (coupon) => {
  switch (coupon.type) {
    case COUPON_TYPES.EMI:
      return coupon.emi.map((e) => e.amount).join(" + ");
    case COUPON_TYPES.FLAT:
      return coupon.flat.amount;
    case COUPON_TYPES.PERCENTAGE:
      return `${coupon.percentage.percent} (Max ${coupon.percentage.maxDiscount})%`;

    default:
      return "";
  }
};
