import Admins from "../pages/Admins";
import Certificates from "../pages/Certificates";
import Colleges from "../pages/Colleges";
import Companies from "../pages/Companies";
import Coupons from "../pages/Coupons";
import CreateTest from "../pages/CreateTest";
import Home from "../pages/Home";
import NoticeBoard from "../pages/NoticeBoard";
import PreparationPlans from "../pages/PreparationPlan";
import PricingPlans from "../pages/PricingPlan";
import Reports from "../pages/Reports";
import StudentDetails from "../pages/StudentDetails";
import Students from "../pages/Students";
import SuperAdminStudents from "../pages/SuperAdminStudents";
import Tests from "../pages/Tests";
import { USER_ROLES } from "../utils/constants";

const { SUPERADMIN, COLLEGE_ADMIN, STUDENT, STAFFADMIN } = USER_ROLES;

const routes = {
  home: {
    title: "Home",
    path: "/",
    component: <Home />,
    for: [SUPERADMIN, STAFFADMIN, COLLEGE_ADMIN, STUDENT],
  },
  colleges: {
    title: "Colleges",
    path: "/colleges",
    component: <Colleges />,
    sidebar: true,
    for: [SUPERADMIN],
  },
  companies: {
    title: "Companies",
    path: "/companies",
    component: <Companies />,
    sidebar: true,
    for: [SUPERADMIN],
  },
  students: {
    title: "Students",
    path: "/students",
    component: <Students />,
    sidebar: true,
    for: [COLLEGE_ADMIN],
  },
  studentDetails: {
    title: "Student Details",
    path: "/students/:id",
    getPath: (id) => `/students/${id}`,
    component: <StudentDetails />,
    sidebar: false,
    for: [COLLEGE_ADMIN, STAFFADMIN, SUPERADMIN],
  },
  studentsSuperAdmin: {
    title: "Students",
    path: "/students",
    component: <SuperAdminStudents />,
    sidebar: true,
    for: [STAFFADMIN, SUPERADMIN],
  },
  admins: {
    title: "Admins",
    path: "/admins",
    component: <Admins />,
    sidebar: true,
    for: [COLLEGE_ADMIN],
  },
  noticeboard: {
    title: "Notice Board",
    path: "/noticeboard",
    component: <NoticeBoard />,
    sidebar: true,
    for: [COLLEGE_ADMIN],
  },
  certificates: {
    title: "Certificates",
    path: "/certificates",
    component: <Certificates />,
    sidebar: true,
    for: [SUPERADMIN, STAFFADMIN],
  },
  reports: {
    title: "Reports",
    path: "/reports",
    component: <Reports />,
    sidebar: true,
    for: [COLLEGE_ADMIN],
  },
  tests: {
    title: "Tests",
    path: "/tests",
    component: <Tests />,
    sidebar: true,
    for: [COLLEGE_ADMIN],
  },
  createTest: {
    title: "Create Test",
    path: "/test/create",
    component: <CreateTest />,
    sidebar: false,
    for: [COLLEGE_ADMIN],
  },
  editTest: {
    title: "Create Test",
    path: "/test/edit/:id",
    getPath: (id) => `/test/edit/${id}`,
    component: <CreateTest />,
    sidebar: false,
    for: [COLLEGE_ADMIN],
  },
  preparationPlan: {
    title: "Preparation Plans",
    path: "/preparation-plan",
    component: <PreparationPlans />,
    sidebar: false,
    for: [SUPERADMIN],
  },
  pricingPlan: {
    title: "Pricing Plans",
    path: "/pricing-plan",
    component: <PricingPlans />,
    sidebar: false,
    for: [SUPERADMIN],
  },
  coupons: {
    title: "Coupons",
    path: "/coupons",
    component: <Coupons />,
    sidebar: false,
    for: [SUPERADMIN, STAFFADMIN],
  },
};

export default routes;
