import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { getCoupons } from "../api/superAdmin";
import CouponCreateEdit from "../components/Coupon/CouponCreateEdit";
import CouponDetails from "../components/Coupon/CouponDetails";
import TableX from "../components/TableX";
import DialogX from "../components/utils/Dialog";
import { getDiscount } from "./utils";

const columns = [
  { field: "code", title: "Code", width: "20%" },
  { field: "plan", title: "Plan", width: "20%", render: (v) => v?.name },
  { field: "type", title: "Type", width: "10%" },
  {
    field: "discount",
    title: "Discount",
    width: "30%",
    render: (v, row) => getDiscount(row),
  },
];

const Coupons = () => {
  const [showForm, setFormOpen] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    getCoupons().then((res) => {
      setCoupons(res.coupons);
    });
  }, []);

  const onView = (id) => {
    setSelectedId(id);
  };

  const onEdit = (id) => {
    setFormOpen(true);
    setSelectedId(id);
  };

  const closeForm = () => {
    setFormOpen(false);
    setSelectedId(null);
  };

  return (
    <>
      <CouponCreateEdit
        handleCancel={closeForm}
        obj={coupons.find((p) => p._id === selectedId)}
        open={showForm}
      />
      {selectedId && !showForm ? (
        <DialogX
          open={selectedId}
          handleClose={() => setSelectedId(null)}
          title={`Coupon Details`}
          content={
            <CouponDetails data={coupons.find((p) => p._id === selectedId)} />
          }
        />
      ) : null}

      <TableX
        title="Coupons"
        action={<Button onClick={() => setFormOpen(true)}>Add</Button>}
        rows={coupons}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Edit",
            icon: <EditIcon fontSize="small" />,
            onClick: onEdit,
          },
          {
            title: "View",
            icon: <InfoOutlinedIcon fontSize="small" />,
            onClick: onView,
          },
        ]}
      />
    </>
  );
};

export default Coupons;
