import {
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { getDiscount } from "../../pages/utils";
import { InfoItem } from "../StudentInfo";

const CouponDetails = ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <Box>
      <Paper variant="outlined" className="student-info-container">
        <InfoItem title="Type" value={data.type} />
        <InfoItem title="Plan" value={data.plan.name} />
        <InfoItem title="Coupon Code" value={<>&#8377; {data.code}</>} />
        <InfoItem title="Discount" value={getDiscount(data)} />
      </Paper>
      <Paper variant="outlined" className="student-info-container">
        <Typography variant="button">Users:</Typography>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {/* here userId is actually user object */}
          {data.users.map(({ user }) => {
            return (
              <ListItem key={user._id} disablePadding>
                <ListItemText primary={user.name} secondary={user.email} />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Box>
  );
};

export default CouponDetails;
