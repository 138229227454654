import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isToday"));

// util function to get date as today, yesterday, or date using dayjs
export const getDate = (date) => {
  const today = dayjs();
  const day = dayjs(date);
  if (today.isSame(day, "day")) {
    return "Today";
  } else if (today.subtract(1, "day").isSame(day, "day")) {
    return "Yesterday";
  } else {
    return day.format("DD MMM YYYY");
  }
};

export const isToday = (date) => dayjs(date).isToday();

export const sortByDate = (listOfObjects, key) => {
  return [...listOfObjects].sort((a, b) =>
    dayjs(a[key]) > dayjs(b[key]) ? -1 : 1
  );
};
