import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  createCollege,
  deleteCollege,
  editCollege,
  getColleges,
} from "../api/superAdmin";
import { AddCollegeForm, EditCollegeForm } from "../components/CollegeForm";
import TableX from "../components/TableX";

const columns = [
  { field: "_id", title: "ID", width: "10%" },
  { field: "name", title: "Name", width: "30%" },
  { field: "studentCredits", title: "Credits", width: "10%" },
  {
    field: "validTill",
    title: "Validity",
    width: "20%",
    render: (v) => dayjs(v).format("MMM DD, YYYY"),
  },
  {
    field: "isTrial",
    title: "Trial account",
    width: "10%",
    render: (value) => (value ? <CheckCircleIcon color="success" /> : null),
  },
  {
    field: "students",
    title: "Students",
    width: "10%",
    render: (r) => r.length,
  },
  { field: "admins", title: "Admins", width: "10%", render: (r) => r.length },
];

const Colleges = () => {
  const [colleges, setColleges] = useState([]);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getColleges().then((res) => setColleges(res.colleges));
  }, []);

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    setAddFormOpen(false);
  };

  const onAddCollege = (data) => {
    createCollege(data).then((res) => {
      setColleges([...colleges, res.college]);
      handleClose();
    });
  };
  const onEditCollege = (id) => {
    setEditId(id);
  };
  const onEditSubmit = (data) => {
    editCollege(editId, data).then((res) => {
      setColleges(colleges.map((c) => (c._id === editId ? res.college : c)));
    });
  };
  const onDeleteCollege = (id) => {
    deleteCollege(id).then(() => {
      setColleges(colleges.filter((c) => c._id !== id));
    });
  };

  return (
    <>
      <TableX
        title="Colleges"
        action={<Button onClick={handleClickOpen}>Add</Button>}
        rows={colleges}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Edit",
            icon: <EditIcon fontSize="small" />,
            onClick: onEditCollege,
          },
          {
            title: "Delete",
            icon: <DeleteIcon fontSize="small" color="error" />,
            onClick: onDeleteCollege,
          },
        ]}
      />
      <AddCollegeForm
        open={addFormOpen}
        handleAdd={onAddCollege}
        handleClose={handleClose}
      />
      <EditCollegeForm
        open={Boolean(editId)}
        obj={colleges.find((c) => c._id === editId)}
        handleSubmit={onEditSubmit}
        handleClose={() => setEditId(null)}
      />
    </>
  );
};

export default Colleges;
