import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  createCompany,
  deleteCompany,
  editCompany,
  getCompanies,
} from "../api/superAdmin";
import { AddCompanyForm, EditCompanyForm } from "../components/CompanyForm";
import TableX from "../components/TableX";

const columns = [
  { field: "_id", title: "ID", width: "10%" },
  { field: "name", title: "Name", width: "30%" },
  {
    field: "validTill",
    title: "Validity",
    width: "20%",
    render: (v) => dayjs(v).format("MMM DD, YYYY"),
  },
  {
    field: "recruiters",
    title: "Recruiters",
    width: "10%",
    render: (r) => r.length,
  },
];

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getCompanies().then((res) => setCompanies(res.companies));
  }, []);

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    setAddFormOpen(false);
  };

  const onAddCompany = (data) => {
    createCompany(data).then((res) => {
      setCompanies([...companies, res.company]);
      handleClose();
    });
  };
  const onEditCompany = (id) => {
    setEditId(id);
  };
  const onEditSubmit = (data) => {
    editCompany(editId, data).then((res) => {
      setCompanies(companies.map((c) => (c._id === editId ? res.company : c)));
    });
  };
  const onDeleteCompany = (id) => {
    deleteCompany(id).then(() => {
      setCompanies(companies.filter((c) => c._id !== id));
    });
  };

  return (
    <>
      <TableX
        title="Companies"
        action={<Button onClick={handleClickOpen}>Add</Button>}
        rows={companies}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Edit",
            icon: <EditIcon fontSize="small" />,
            onClick: onEditCompany,
          },
          {
            title: "Delete",
            icon: <DeleteIcon fontSize="small" color="error" />,
            onClick: onDeleteCompany,
          },
        ]}
      />
      <AddCompanyForm
        open={addFormOpen}
        handleAdd={onAddCompany}
        handleClose={handleClose}
      />
      <EditCompanyForm
        open={Boolean(editId)}
        obj={companies.find((c) => c._id === editId)}
        handleSubmit={onEditSubmit}
        handleClose={() => setEditId(null)}
      />
    </>
  );
};

export default Companies;
