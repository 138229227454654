import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getTests, deleteTest } from "../api/test";
import { convertToMap } from "../utils/object";
import TestCard, {
  TestCardActions,
  TestSectionsTimeline,
} from "../components/TestCard";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import Empty from "../components/Empty";
import { useNavigate } from "react-router";
import routes from "../layout/routes";
import Loader from "../components/Loader";
import { testStatuses } from "../utils/constants";
import DialogX from "../components/utils/Dialog";
import TestReport from "../components/TestReport";

const Tests = () => {
  const [tests, setTests] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showReport, setShowReport] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTests();
  }, []);

  const fetchTests = async () => {
    setLoading(true);
    const res = await getTests();
    const { tests } = res.data;
    setTests(convertToMap(tests, "_id"));
    setLoading(false);
  };

  const onDelete = async (testId) => {
    try {
      await deleteTest(testId);
      setTests((tests) => {
        const newTests = { ...tests };
        delete newTests[testId];
        return newTests;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showTest = (testId) => {
    setSelectedTest(testId);
    setDialogOpen(true);
  };

  const goToCreateTestPage = () => {
    navigate(routes.createTest.path);
  };

  const sortedTests = useMemo(() => {
    return Object.values(tests)
      ?.sort((a, b) => (dayjs(a.startTime) < dayjs(b.startTime) ? 1 : -1))
      ?.sort((a, b) =>
        a.status === testStatuses.CANCELLED
          ? 1
          : b.status === testStatuses.CANCELLED
          ? -1
          : 0
      );
  }, [tests]);

  return (
    <>
      <DialogX
        open={Boolean(showReport)}
        handleClose={() => setShowReport(null)}
        title="Test Report"
        content={<TestReport testId={showReport} />}
      />
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll={"paper"}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            pl: 3,
          }}
        >
          {tests[selectedTest]?.title}
          <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{
            height: "60vh",
          }}
        >
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={tests[selectedTest]} />
        </DialogContent>
        <DialogActions>
          <TestCardActions obj={tests[selectedTest]} />
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            margin: 1,
            width: "100%",
            mihHeight: 200,
            maxHeight: 280,
            display: "flex",
            flexDirection: "column",
            borderRadius: 2,
          }}
        >
          <CardMedia component="img" height="160" src={"/test.jpg"} alt="" />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ fontWeight: "bold" }}
            >
              Tests
            </Typography>
            <Button variant="outlined" onClick={goToCreateTestPage}>
              Create
            </Button>
          </CardContent>
        </Card>
        {Object.keys(tests).length === 0 ? (
          <Card
            variant="outlined"
            sx={{
              margin: 1,
              width: "100%",
              height: 180,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: 2,
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Empty
                message={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">
                      You don't have any tests.
                    </Typography>
                    <Button
                      sx={{ mt: 2 }}
                      variant="outlined"
                      onClick={goToCreateTestPage}
                    >
                      Create one
                    </Button>
                  </Box>
                }
              />
            )}
          </Card>
        ) : (
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {sortedTests.map((t) => (
              <Grid item key={t._id} xs={12} s={6} md={6} lg={4} xl={3}>
                <TestCard
                  obj={t}
                  onDelete={() => onDelete(t._id)}
                  onExpand={() => showTest(t._id)}
                  onShowReport={() => setShowReport(t._id)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Tests;
