import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Box, Button, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { getPricingPlans, updatePricingPlans } from "../api/superAdmin";
import TableX from "../components/TableX";
import { FEATURES } from "../utils/constants";

const PricingPlans = () => {
  const [plans, setPlans] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [planFeatures, setPlanFeatures] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init().then(() => {
      setLoading(false);
    });
  }, []);

  const toggle = (feature, planId) => (e) => {
    const checked = e.target.checked;
    if (checked) {
      setPlanFeatures({
        ...planFeatures,
        [planId]: [...planFeatures[planId], feature],
      });
    } else {
      setPlanFeatures({
        ...planFeatures,
        [planId]: planFeatures[planId].filter((f) => f !== feature),
      });
    }
  };

  console.log(planFeatures);

  const columns = [
    { field: "feature", title: "Feature", render: (v) => v.name },
    ...plans.map((p) => ({
      field: p._id,
      title: p.name,
      render: (v, row) => {
        if (editMode) {
          return (
            <Switch
              edge="end"
              onChange={toggle(row.feature.value, p._id)}
              checked={planFeatures[p._id].indexOf(row.feature.value) !== -1}
            />
          );
        }
        return v ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CancelIcon color="error" />
        );
      },
    })),
  ];

  // {feature: "Job postings", "plan1": true, "plan2": false, "plan3": true}
  const rows = FEATURES.map((f) => {
    const row = { feature: f };
    plans.forEach((p) => {
      row[p._id] = p.features.includes(f.value);
    });
    return row;
  });

  const handleEdit = () => setEditMode(true);
  const cancelEdit = () => setEditMode(false);

  const init = async () => {
    const res = await getPricingPlans();
    setPlans(res.plans);
    const planFeatures = res.plans.reduce((acc, p) => {
      acc[p._id] = p.features;
      return acc;
    }, {});
    setPlanFeatures(planFeatures);
  };

  const updatePlans = async () => {
    // Send the updated plans to the server
    // console.log(planFeatures);
    setLoading(true);
    try {
      await updatePricingPlans(planFeatures);
      await init();
      setEditMode(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TableX
      title="Plans"
      loading={loading}
      action={
        editMode ? (
          <Box>
            <Button onClick={cancelEdit}>Cancel</Button>
            <Button onClick={updatePlans} variant="contained">
              Done
            </Button>
          </Box>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
      rows={rows}
      columns={columns}
      showIndexColumn
      keyExtractor={(r) => r._id}
    />
  );
};

export default PricingPlans;
