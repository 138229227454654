import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useParams } from "react-router";
import { unlockTopic } from "../api/superAdmin";
import withAuth from "../hoc/withAuth";
import { lockStatuses } from "../utils/constants";

export const LockUnlockButton = ({ status, onClick }) => {
  return (
    <IconButton onClick={onClick}>
      {status === lockStatuses.UNLOCKED ? (
        <LockOpenIcon />
      ) : status === lockStatuses.FINISHED ? (
        <CheckCircleIcon color="success" />
      ) : (
        <LockIcon />
      )}
    </IconButton>
  );
};

const TopicProgress = ({ topicProgress, isAdmin }) => {
  const { id: userId } = useParams();

  const toggleTopicLock = () => {
    // TODO: implement lock api
    unlockTopic({
      topicId: topicProgress.topicId,
      userId,
    });
    // lockTopic(topicProgress.topicId);
  };

  const toggleSubTopicLock = (subTopicId) => () => {
    console.log("toggle subtopic lock", subTopicId);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          alignItems: "center",
          ":hover": {
            backgroundColor: "#eee",
          },
        }}
      >
        {isAdmin && (
          <LockUnlockButton
            status={topicProgress.status}
            onClick={toggleTopicLock}
          />
        )}
        <Typography>{topicProgress.title}</Typography>
        <LinearProgress
          variant="determinate"
          value={topicProgress.progress}
          sx={{
            m: "auto",
            mr: 2,
            width: "50%",
          }}
        />
        <Typography>{topicProgress.progress || 0}%</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List dense>
          {topicProgress.subTopicProgress.map((subtopic) => (
            <ListItem
              key={subtopic.subTopicId}
              sx={{
                border: "1px solid #ccc",
                ":first-of-type": {
                  borderTopLeftRadius: 4,
                  borderTopRightRadius: 4,
                },
                ":not(:first-of-type):not(:last-of-type)": {
                  borderRadius: 0,
                },
                ":not(:first-of-type)": {
                  borderTop: "none",
                },
                ":last-of-type": {
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                },
              }}
              secondaryAction={(subtopic.progress || 0) + "%"}
            >
              {/* {isAdmin && (
                <ListItemIcon>
                  <LockUnlockButton
                    status={subtopic.status}
                    onClick={toggleSubTopicLock(subtopic.subTopicId)}
                  />
                </ListItemIcon>
              )} */}
              <ListItemText primary={subtopic.title} />
              <LinearProgress
                variant="determinate"
                value={subtopic.progress}
                sx={{ width: "50%", mr: 2 }}
              />
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const StudentProgress = ({ isSuperAdmin, isStaffAdmin, data: progress }) => {
  return (
    <Paper variant="outlined" className="student-info-container">
      <Typography variant="h6" gutterBottom>
        Progress
      </Typography>
      {progress.map((p) => (
        <TopicProgress
          key={p.topicId}
          isAdmin={isSuperAdmin || isStaffAdmin}
          topicProgress={p}
        />
      ))}
    </Paper>
  );
};

export default withAuth(StudentProgress);
