import { LoadingButton } from "@mui/lab";
import { Alert, Button, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useAllTestsOfUser } from "../api/college";
import withAuth from "../hoc/withAuth";
import {
  testStatuses,
  testStatusesColor,
  testStatusesLabel,
  testUserStatuses,
} from "../utils/constants";
import StudentTestReport from "./Reports/StudentTestReport";
import TableX from "./TableX";
import DialogX from "./utils/Dialog";

const StudentTests = ({ user, studentId }) => {
  const [showReport, setShowReport] = useState(null);
  const { tests, mutate, isLoading, isValidating, error } = useAllTestsOfUser(
    user,
    studentId
  );

  const columns = useMemo(
    () => [
      { title: "Title", field: "title", width: "35%" },
      {
        title: "Score",
        field: "score",
        width: "5%",
        render: (v) => `${v.score}/${v.total}`,
      },
      {
        title: "Test Status",
        field: "testStatus",
        width: "10%",
        render: (v) => (
          <Typography color={testStatusesColor[v]}>
            {testStatusesLabel[v]}
          </Typography>
        ),
        filterValue: (row) => ({
          value: row?.testStatus,
          title: testStatusesLabel[row?.testStatus],
        }),
      },
      {
        title: "Student Attempt",
        field: "userStatus",
        width: "10%",
        render: (v) => (
          <Typography color={testStatusesColor[v]}>
            {testStatusesLabel[v]}
          </Typography>
        ),
        filterValue: (row) => ({
          value: row?.userStatus,
          title: testStatusesLabel[row?.userStatus],
        }),
      },
      {
        title: "Date",
        field: "startTime",
        width: "15%",
        render: (v) => (
          <Typography variant="body2">
            {dayjs(v).format("DD MMM YYYY")}
          </Typography>
        ),
        onSort: (a, b) => new Date(b.startTime) - new Date(a.startTime),
      },
      {
        title: "Actions",
        width: "25%",
        render: (_, r) =>
          r.userStatus === testUserStatuses.SUBMITTED &&
          r.testStatus === testStatuses.FINISHED ? (
            <Button variant="text" onClick={() => setShowReport(r._id)}>
              Report
            </Button>
          ) : (
            <Typography variant="body1">-</Typography>
          ),
      },
    ],
    []
  );

  return (
    <Paper variant="outlined">
      {error && <Alert severity="error">{error.message}</Alert>}
      <DialogX
        open={Boolean(showReport)}
        handleClose={() => setShowReport(null)}
        title="Test Report"
        content={
          <StudentTestReport studentId={studentId} testId={showReport} />
        }
        primaryAction={() => setShowReport(null)}
        primaryActionTitle="Done"
      />
      <TableX
        title="Tests"
        loading={isLoading}
        action={
          <LoadingButton
            loading={isLoading || isValidating}
            variant="text"
            onClick={() => mutate([])}
          >
            Refresh
          </LoadingButton>
        }
        showIndexColumn
        keyExtractor={(r) => r._id}
        style={{ width: "auto" }}
        tableStyle={{ width: "100%", mx: 0 }}
        columns={columns}
        rows={tests}
      />
    </Paper>
  );
};

export default withAuth(StudentTests);
