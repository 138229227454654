import dayjs from "dayjs";
import { QuestionTypes } from "./constants";

export const getCodingQuestionResponse = (responses) => {
  const sortedResponses = responses.sort((a, b) =>
    dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1
  ); // sorted by createdAt with most recent submission at index 0

  let finalResponse;
  const submittedResponses = sortedResponses.filter((r) => r.submitted);
  if (submittedResponses.length === 0) {
    finalResponse = sortedResponses[0];
  } else {
    finalResponse = submittedResponses[0];
  }
  return finalResponse;
};

export const getMCQQuestionResponse = (responses) => {
  return responses?.sort((a, b) =>
    dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1
  )[0];
};

export const calculateScore = (sections, responses) => {
  let score = 0;
  const questions = {};
  sections.forEach((section) => {
    section.questions.forEach((question) => {
      questions[question._id] = question;
    });
  });

  Object.keys(responses).forEach((questionId) => {
    const question = questions[questionId];
    switch (question.type) {
      case QuestionTypes.MCQ: {
        const finalResponse = getMCQQuestionResponse(responses[questionId]);
        const correctOption = question.options.find((opt) => opt.isCorrect);
        if (finalResponse.optionId === correctOption._id) score++;
        break;
      }
      case QuestionTypes.Code:
        {
          const finalResponse = getCodingQuestionResponse(
            responses[questionId]
          );
          score += finalResponse
            ? finalResponse.testCasesResult.filter((testCase) => testCase)
                .length / finalResponse.testCasesResult.length
            : 0;
        }
        break;
      default:
        break;
    }
  });
  return `${score} / ${Object.keys(questions).length}`;
};
