import * as React from "react";
import dayjs from "dayjs";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  Typography,
  Button,
  Paper,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue } from "@mui/material/colors";
import {
  createNoticeBoardPostReply,
  deleteNoticeBoardPostReply,
  getNoticeBoardPostReplies,
  updateNoticeBoardPostReply,
} from "../api/college";
import Loading from "./Loading";
import { Box } from "@mui/system";
import { OptionsButton } from "./utils/buttons";
import Empty from "./Empty";
import NoticeBoardPostReplyCard from "./NoticeBoardPostReplyCard";
import { InputSend } from "./utils/inputs";

export default function NoticeBoardPostCard({
  post,
  userId,
  onEdit,
  onDelete,
}) {
  const [loading, setLoading] = React.useState(false);
  const [replies, setReplies] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const isOwner = userId === post.from._id;

  const handleExpandClick = async () => {
    setExpanded(!expanded);
    try {
      setLoading(true);
      const res = await getNoticeBoardPostReplies(post._id);
      setReplies(res.replies);
    } finally {
      setLoading(false);
    }
  };

  const onCreateReply = async (message) => {
    const res = await createNoticeBoardPostReply({
      postId: post._id,
      message,
    });
    setReplies([...replies, res.reply]);
  };

  const onEditReply = (replyId) => async (message) => {
    const res = await updateNoticeBoardPostReply({
      postId: post._id,
      replyId,
      message,
    });
    setReplies((replies) =>
      replies.map((reply) => {
        if (reply._id === replyId) {
          return res.reply;
        }
        return reply;
      })
    );
  };

  const onDeleteReply = (replyId) => async () => {
    await deleteNoticeBoardPostReply({ postId: post._id, replyId });
    setReplies((replies) => replies.filter((reply) => reply._id !== replyId));
  };

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: blue[500] }}
            src={post.from.avatar}
            alt={post.from.name}
          />
        }
        action={
          isOwner && (
            <OptionsButton
              actions={[
                {
                  title: "Edit",
                  onClick: onEdit,
                  icon: <EditIcon />,
                },
                {
                  title: "Delete",
                  onClick: onDelete,
                  icon: <DeleteIcon color="error" />,
                },
              ]}
            />
          )
        }
        title={post.from.name}
        subheader={dayjs(post.createdAt).format("MMMM DD, YYYY")}
      />
      <CardContent
        sx={{
          maxHeight: "150px",
          overflow: "scroll",
          pt: 0,
        }}
      >
        <Typography variant="h6" component="div">
          {post.title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {post.content}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          onClick={handleExpandClick}
          endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {expanded ? "Hide Replies" : "Show Replies"}
        </Button>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {loading ? (
          <Loading />
        ) : replies?.length > 0 ? (
          <Box sx={{ p: 1, maxHeight: "300px", overflow: "scroll" }}>
            <Typography
              gutterBottom
              variant="button"
              component="div"
              sx={{ ml: 1 }}
            >
              Replies:
            </Typography>
            {replies.map((reply) => (
              <React.Fragment key={reply._id}>
                <Divider />
                <NoticeBoardPostReplyCard
                  reply={reply}
                  userId={userId}
                  onEdit={onEditReply(reply._id)}
                  onDelete={onDeleteReply(reply._id)}
                />
              </React.Fragment>
            ))}
          </Box>
        ) : (
          <Paper variant="outlined" sx={{ p: 1, m: 1 }}>
            <Empty message="No replies yet" />
          </Paper>
        )}

        <InputSend placeholder="Add your reply..." onSubmit={onCreateReply} />
      </Collapse>
    </Card>
  );
}
