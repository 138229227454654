import { Paper, Typography } from "@mui/material";

export const Well = ({ title, children }) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: 4,
        my: 1,
      }}
    >
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
