import { Box, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { TRANSACTION_STATUS } from "../utils/constants";
import { InfoItem } from "./StudentInfo";

const TransactionDetails = ({ data }) => {
  return (
    <Box>
      <Paper variant="outlined" className="student-info-container">
        <InfoItem title="Transaction ID" value={data.merchantTransactionId} />
        <InfoItem title="Gateway Transaction ID" value={data.transactionId} />
        <InfoItem title="Amount" value={<>&#8377; {data.amount}</>} />
        <InfoItem
          title="Status"
          value={TRANSACTION_STATUS[data.status]?.label}
        />
        <InfoItem
          title="Date"
          value={dayjs(data.createdAt).format("HH:mm DD MMM YYYY")}
        />
        <InfoItem title="Coupon " value={data.couponCode} />
      </Paper>
      <Paper variant="outlined" className="student-info-container">
        <Typography variant="button">Extra:</Typography>
        <Typography variant="body1" component="pre">
          {JSON.stringify(data.paymentDetails, null, 2)}
        </Typography>
      </Paper>
    </Box>
  );
};

export default TransactionDetails;
