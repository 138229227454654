import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getStudentsReportsOfAllTests } from "../api/college";
import TableX from "../components/TableX";
import Loader from "../components/Loader";
import dayjs from "dayjs";
import {
  testStatuses,
  testStatusesLabel,
  testUserStatuses,
} from "../utils/constants";
import { sortByDate } from "../utils/date";

const getStyle = (value) => {
  const scale = Math.floor(value / 10);
  const backgroundColors = [
    "#FF0000CC",
    "#FF3300CC",
    "#FF6600CC",
    "#FF9900CC",
    "#FFCC00CC",
    "#CCFF00CC",
    "#99FF00CC",
    "#66FF00CC",
    "#33FF00CC",
    "#00CC00CC",
    "#009900CC",
  ];

  return {
    backgroundColor: backgroundColors[scale],
    color: "black",
    fontWeight: "500",
    textAlign: "center",
  };
};

const sortByScore = (testId) => (a, b) => {
  const aScore = a[testId];
  const bScore = b[testId];
  if (aScore === "-") return 1;
  if (bScore === "-") return -1;
  return aScore > bScore ? 1 : -1;
};

const dummyRows = [
  {
    student: {
      _id: "1",
      name: "Student 1",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 10,
    "60e9d7e4e6d4f8b1f8b6f7a6": 90,
    "60e9d7e4e6d4f8b1f8b6f7a7": 80,
    "60e9d7e4e6d4f8b1f8b6f7a8": 40,
    "60e9d7e4e6d4f8b1f8b6f7a9": 50,
    "60e9d7e4e6d4f8b1f8b6f7a10": 34,
    "60e9d7e4e6d4f8b1f8b6f7a11": 62,
    "60e9d7e4e6d4f8b1f8b6f7a12": 80,
  },
  {
    student: {
      _id: "2",
      name: "Student 2",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 45,
    "60e9d7e4e6d4f8b1f8b6f7a6": 90,
    "60e9d7e4e6d4f8b1f8b6f7a7": 55,
    "60e9d7e4e6d4f8b1f8b6f7a8": 40,
    "60e9d7e4e6d4f8b1f8b6f7a9": 50,
    "60e9d7e4e6d4f8b1f8b6f7a10": 86,
    "60e9d7e4e6d4f8b1f8b6f7a11": 70,
    "60e9d7e4e6d4f8b1f8b6f7a12": 32,
  },
  {
    student: {
      _id: "3",
      name: "Student 3",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 42,
    "60e9d7e4e6d4f8b1f8b6f7a6": 15,
    "60e9d7e4e6d4f8b1f8b6f7a7": 73,
    "60e9d7e4e6d4f8b1f8b6f7a8": 88,
    "60e9d7e4e6d4f8b1f8b6f7a9": 29,
    "60e9d7e4e6d4f8b1f8b6f7a10": 57,
    "60e9d7e4e6d4f8b1f8b6f7a11": 10,
    "60e9d7e4e6d4f8b1f8b6f7a12": 95,
  },
  {
    student: {
      _id: "4",
      name: "Student 4",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 68,
    "60e9d7e4e6d4f8b1f8b6f7a6": 20,
    "60e9d7e4e6d4f8b1f8b6f7a7": 45,
    "60e9d7e4e6d4f8b1f8b6f7a8": 82,
    "60e9d7e4e6d4f8b1f8b6f7a9": 5,
    "60e9d7e4e6d4f8b1f8b6f7a10": 71,
    "60e9d7e4e6d4f8b1f8b6f7a11": 36,
    "60e9d7e4e6d4f8b1f8b6f7a12": 79,
  },
  {
    student: {
      _id: "5",
      name: "Student 5",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 13,
    "60e9d7e4e6d4f8b1f8b6f7a6": 62,
    "60e9d7e4e6d4f8b1f8b6f7a7": 50,
    "60e9d7e4e6d4f8b1f8b6f7a8": 91,
    "60e9d7e4e6d4f8b1f8b6f7a9": 7,
    "60e9d7e4e6d4f8b1f8b6f7a10": 33,
    "60e9d7e4e6d4f8b1f8b6f7a11": 85,
    "60e9d7e4e6d4f8b1f8b6f7a12": 48,
  },
  {
    student: {
      _id: "6",
      name: "Student 6",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 55,
    "60e9d7e4e6d4f8b1f8b6f7a6": 78,
    "60e9d7e4e6d4f8b1f8b6f7a7": 16,
    "60e9d7e4e6d4f8b1f8b6f7a8": 39,
    "60e9d7e4e6d4f8b1f8b6f7a9": 25,
    "60e9d7e4e6d4f8b1f8b6f7a10": 64,
    "60e9d7e4e6d4f8b1f8b6f7a11": 89,
    "60e9d7e4e6d4f8b1f8b6f7a12": 12,
  },
  {
    student: {
      _id: "7",
      name: "Student 7",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 94,
    "60e9d7e4e6d4f8b1f8b6f7a6": 31,
    "60e9d7e4e6d4f8b1f8b6f7a7": 61,
    "60e9d7e4e6d4f8b1f8b6f7a8": 47,
    "60e9d7e4e6d4f8b1f8b6f7a9": 14,
    "60e9d7e4e6d4f8b1f8b6f7a10": 74,
    "60e9d7e4e6d4f8b1f8b6f7a11": 22,
    "60e9d7e4e6d4f8b1f8b6f7a12": 68,
  },
  {
    student: {
      _id: "8",
      name: "Student 8",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 83,
    "60e9d7e4e6d4f8b1f8b6f7a6": 27,
    "60e9d7e4e6d4f8b1f8b6f7a7": 49,
    "60e9d7e4e6d4f8b1f8b6f7a8": 76,
    "60e9d7e4e6d4f8b1f8b6f7a9": 11,
    "60e9d7e4e6d4f8b1f8b6f7a10": 61,
    "60e9d7e4e6d4f8b1f8b6f7a11": 36,
    "60e9d7e4e6d4f8b1f8b6f7a12": 92,
  },
  {
    student: {
      _id: "9",
      name: "Student 9",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 17,
    "60e9d7e4e6d4f8b1f8b6f7a6": 74,
    "60e9d7e4e6d4f8b1f8b6f7a7": 88,
    "60e9d7e4e6d4f8b1f8b6f7a8": 42,
    "60e9d7e4e6d4f8b1f8b6f7a9": 59,
    "60e9d7e4e6d4f8b1f8b6f7a10": 23,
    "60e9d7e4e6d4f8b1f8b6f7a11": 95,
    "60e9d7e4e6d4f8b1f8b6f7a12": 31,
  },
  {
    student: {
      _id: "10",
      name: "Student 10",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 54,
    "60e9d7e4e6d4f8b1f8b6f7a6": 39,
    "60e9d7e4e6d4f8b1f8b6f7a7": 81,
    "60e9d7e4e6d4f8b1f8b6f7a8": 68,
    "60e9d7e4e6d4f8b1f8b6f7a9": 6,
    "60e9d7e4e6d4f8b1f8b6f7a10": 76,
    "60e9d7e4e6d4f8b1f8b6f7a11": 47,
    "60e9d7e4e6d4f8b1f8b6f7a12": 22,
  },
  {
    student: {
      _id: "11",
      name: "Student 11",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 29,
    "60e9d7e4e6d4f8b1f8b6f7a6": 67,
    "60e9d7e4e6d4f8b1f8b6f7a7": 53,
    "60e9d7e4e6d4f8b1f8b6f7a8": 80,
    "60e9d7e4e6d4f8b1f8b6f7a9": 14,
    "60e9d7e4e6d4f8b1f8b6f7a10": 44,
    "60e9d7e4e6d4f8b1f8b6f7a11": 75,
    "60e9d7e4e6d4f8b1f8b6f7a12": 90,
  },
  {
    student: {
      _id: "12",
      name: "Student 12",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 63,
    "60e9d7e4e6d4f8b1f8b6f7a6": 18,
    "60e9d7e4e6d4f8b1f8b6f7a7": 74,
    "60e9d7e4e6d4f8b1f8b6f7a8": 47,
    "60e9d7e4e6d4f8b1f8b6f7a9": 89,
    "60e9d7e4e6d4f8b1f8b6f7a10": 32,
    "60e9d7e4e6d4f8b1f8b6f7a11": 58,
    "60e9d7e4e6d4f8b1f8b6f7a12": 7,
  },
  {
    student: {
      _id: "13",
      name: "Student 13",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 72,
    "60e9d7e4e6d4f8b1f8b6f7a6": 10,
    "60e9d7e4e6d4f8b1f8b6f7a7": 54,
    "60e9d7e4e6d4f8b1f8b6f7a8": 38,
    "60e9d7e4e6d4f8b1f8b6f7a9": 81,
    "60e9d7e4e6d4f8b1f8b6f7a10": 23,
    "60e9d7e4e6d4f8b1f8b6f7a11": 66,
    "60e9d7e4e6d4f8b1f8b6f7a12": 94,
  },
  {
    student: {
      _id: "14",
      name: "Student 14",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 14,
    "60e9d7e4e6d4f8b1f8b6f7a6": 63,
    "60e9d7e4e6d4f8b1f8b6f7a7": 47,
    "60e9d7e4e6d4f8b1f8b6f7a8": 79,
    "60e9d7e4e6d4f8b1f8b6f7a9": 34,
    "60e9d7e4e6d4f8b1f8b6f7a10": 89,
    "60e9d7e4e6d4f8b1f8b6f7a11": 18,
    "60e9d7e4e6d4f8b1f8b6f7a12": 56,
  },
  {
    student: {
      _id: "15",
      name: "Student 15",
    },
    "60e9d7e4e6d4f8b1f8b6f7a5": 45,
    "60e9d7e4e6d4f8b1f8b6f7a6": 79,
    "60e9d7e4e6d4f8b1f8b6f7a7": 23,
    "60e9d7e4e6d4f8b1f8b6f7a8": 67,
    "60e9d7e4e6d4f8b1f8b6f7a9": 51,
    "60e9d7e4e6d4f8b1f8b6f7a10": 94,
    "60e9d7e4e6d4f8b1f8b6f7a11": 15,
    "60e9d7e4e6d4f8b1f8b6f7a12": 72,
  },
];

const dummyColumns = [
  {
    title: "Student",
    field: "student",
    sticky: true,
    render: (value) => value?.name,
    onSort: (a, b) => {
      if (!a?.student?.name) return 1;
      if (!b?.student?.name) return -1;
      return a.student.name > b.student.name ? 1 : -1;
    },
  },
  {
    title: "Test 1",
    field: "60e9d7e4e6d4f8b1f8b6f7a5",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a5"),
  },
  {
    title: "Test 2",
    field: "60e9d7e4e6d4f8b1f8b6f7a6",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a6"),
  },
  {
    title: "Test 3",
    field: "60e9d7e4e6d4f8b1f8b6f7a7",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a7"),
  },
  {
    title: "Test 4",
    field: "60e9d7e4e6d4f8b1f8b6f7a8",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a8"),
  },
  {
    title: "Test 5",
    field: "60e9d7e4e6d4f8b1f8b6f7a9",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a9"),
  },
  {
    title: "Test 6",
    field: "60e9d7e4e6d4f8b1f8b6f7a10",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a10"),
  },
  {
    title: "Test 7",
    field: "60e9d7e4e6d4f8b1f8b6f7a11",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a11"),
  },
  {
    title: "Test 8",
    field: "60e9d7e4e6d4f8b1f8b6f7a12",
    getStyle: (value) => getStyle(value),
    onSort: sortByScore("60e9d7e4e6d4f8b1f8b6f7a12"),
  },
];

const Reports = () => {
  const [userTestScores, setUserTestScores] = useState([]);
  const [students, setStudents] = useState([]);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);

  const dummy = window.location.href.includes("dummy");

  const init = useCallback(async () => {
    if (dummy) {
      setLoading(false);
      return;
    }

    setLoading(true);
    getStudentsReportsOfAllTests()
      .then((res) => {
        const { tests, students, userTestScores } = res;
        setUserTestScores(userTestScores);
        setStudents(students);
        setTests(tests);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dummy]);

  useEffect(() => {
    init();
  }, [init]);

  const columns = useMemo(() => {
    const studentCol = {
      title: "Student",
      field: "student",
      sticky: true,
      width: 200,
      render: (value) => value?.name,
      onSort: (a, b) => {
        if (!a?.student?.name) return 1;
        if (!b?.student?.name) return -1;
        return a.student.name.toLowerCase() > b.student.name.toLowerCase()
          ? 1
          : -1;
      },
    };
    if (!tests.length) {
      return [
        studentCol,
        {
          title: "No Tests Conducted",
          field: "no-test",
          sticky: true,
        },
      ];
    }

    const testCols = sortByDate(tests, "startTime").map((test) => ({
      title: (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            title={test.title}
            variant="body1"
            sx={{
              fontWeight: "600",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100px",
            }}
          >
            {test.title}
          </Typography>
          <Typography variant="subtitle2">
            {dayjs(test.startTime).format("DD MMM YY HH:mm")}
          </Typography>
        </Box>
      ),
      field: test._id,
      getStyle: (value) => getStyle(value),
      onSort: sortByScore(test._id),
      width: 150,
    }));

    return [studentCol, ...testCols];
  }, [tests]);

  const rows = useMemo(() => {
    const userIdTestScoreMap = {};
    userTestScores.forEach((userTestScore) => {
      if (!userIdTestScoreMap[userTestScore.userId]) {
        userIdTestScoreMap[userTestScore.userId] = {};
      }
      userIdTestScoreMap[userTestScore.userId][userTestScore.testId] =
        userTestScore;
    });
    const rows = students.map((student) => {
      const row = { student };
      tests.forEach((test) => {
        const userTestScore = userIdTestScoreMap[student._id]?.[test._id];
        row[test._id] =
          userTestScore.userStatus === testUserStatuses.NOT_STARTED &&
          test.status === testStatuses.FINISHED
            ? testStatusesLabel[userTestScore.userStatus]
            : (userTestScore?.score?.total || 0) > 0
            ? (userTestScore.score.score * 100) / userTestScore.score.total
            : "-";
      });
      return row;
    });

    if (!tests.length) {
      rows.forEach((row) => {
        row["no-test"] = "-";
      });
    }

    return rows;
  }, [students, tests, userTestScores]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "90vh",
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <TableX
          keyExtractor={(row) => row.student._id}
          columns={dummy ? dummyColumns : columns}
          rows={dummy ? dummyRows : rows}
          title="Students vs Tests"
        />
      )}
    </Box>
  );
};

export default Reports;
