import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { FileUploadOutlined as UploadIcon } from "@mui/icons-material";
import { uploadCertificate } from "../api/certificate";

const CertificateForm = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [certificateFile, setCertificateFile] = useState(null);

  const handleUpload = async () => {
    try {
      setLoading(true);
      await uploadCertificate({ email, file: certificateFile });
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add certificate</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="User email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />

        <input
          hidden
          accept="image/*"
          type="file"
          id="cert-file"
          onChange={(e) => setCertificateFile(e.target.files[0])}
        />
        <Box>
          <Button
            variant="outlined"
            component="label"
            htmlFor="cert-file"
            startIcon={<UploadIcon />}
            disabled={loading}
          >
            Upload Certificate
          </Button>
          <Typography>{certificateFile?.name}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {loading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <Button
            onClick={handleUpload}
            color="primary"
            disabled={loading || !email || !certificateFile}
          >
            Upload
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CertificateForm;
