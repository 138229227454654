import { Avatar, Box, Divider, Paper, Typography } from "@mui/material";

export const InfoItem = ({ title, value }) => {
  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="subtitle2" sx={{ color: "#444" }}>
        {title}
      </Typography>
      <Typography variant="body1">{value || "-"}</Typography>
    </Box>
  );
};

const StudentInfo = ({ data: student }) => {
  return (
    <Paper
      variant="outlined"
      className="student-info-container"
      sx={{ display: "flex", flexDirection: "row" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Avatar
          alt={student.name}
          src={student.avatar}
          sx={{ width: 140, height: 140, bgcolor: "#474848", fontSize: 56 }}
        />
      </Box>
      <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 4 }} />
      <Box sx={{ flex: 3 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {student.name}
        </Typography>
        <InfoItem title="Email" value={student.email} />
        <InfoItem title="Branch" value={student.branch?.title} />
      </Box>
    </Paper>
  );
};

export default StudentInfo;
