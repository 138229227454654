import useSWR from "swr";
import { delReq, getReq, postReq, putReq } from ".";

export const getColleges = () => {
  return getReq("/superadmin/college");
};

export const createCollege = (data) => {
  return postReq("/superadmin/college", data);
};

export const editCollege = (id, data) => {
  return putReq(`/superadmin/college/${id}`, data);
};

export const deleteCollege = (id) => {
  return delReq(`/superadmin/college/${id}`);
};

export const getCompanies = () => {
  return getReq("/superadmin/company");
};

export const createCompany = (data) => {
  return postReq("/superadmin/company", data);
};

export const editCompany = (id, data) => {
  return putReq(`/superadmin/company/${id}`, data);
};

export const deleteCompany = (id) => {
  return delReq(`/superadmin/company/${id}`);
};

export const getStudents = (searchQuery) => {
  return getReq("/superadmin/student", searchQuery);
};

export const createStudent = (data) => {
  return postReq("/superadmin/student", data);
};

export const editStudent = (id, data) => {
  return putReq(`/superadmin/student/${id}`, data);
};

export const deleteStudent = (id) => {
  return delReq(`/superadmin/student/${id}`);
};

export const createPreparationPlan = (data) => {
  return postReq(`/superadmin/preparation-plan`, data);
};

export const deletePreparationPlan = (id) => {
  return delReq(`/superadmin/preparation-plan/${id}`);
};

export const updatePreparationPlan = (id, data) => {
  return putReq(`/superadmin/preparation-plan/${id}`, data);
};

export const getPreparationPlans = () => {
  return getReq(`/superadmin/preparation-plan`);
};

export const getPricingPlans = () => {
  return getReq(`/superadmin/pricing-plan`);
};

export const updatePricingPlans = (data) => {
  return putReq(`/superadmin/pricing-plan`, data);
};

export const useTransactionsOfUser = (userId) => {
  const {
    data = { transactions: [] },
    mutate,
    isLoading,
    isValidating,
    error,
  } = useSWR(`/superadmin/student/${userId}/transactions`, getReq, {
    revalidateOnFocus: false,
  });
  const { transactions } = data;
  transactions.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return {
    transactions,
    isLoading,
    isValidating,
    error,
    mutate: (transactions) => mutate({ transactions }),
  };
};

export const getCoupons = () => {
  return getReq(`/superadmin/coupons`);
};

export const createCoupon = (data) => {
  return postReq(`/superadmin/coupons`, data);
};

export const updateCoupon = (id, data) => {
  return putReq(`/superadmin/coupons/${id}`, data);
};

export const unlockTopic = ({ topicId, userId }) => {
  return postReq(`/superadmin/topic/${topicId}/unlock`, { userId });
};
