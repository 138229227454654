import { getReq, postReq, delReq, putReq } from ".";

export const createTest = (test) => {
  return postReq(`/admin/college/test`, { test }, true);
};

export const updateTest = (id, test) => {
  return putReq(`/admin/college/test/${id}`, { test }, true);
};

export const getTests = () => {
  return getReq(`/admin/college/test`, {}, true);
};
export const getTest = (id) => {
  return getReq(`/admin/college/test/${id}`, {}, true);
};

export const deleteTest = (testId) => {
  return delReq(`/admin/college/test/${testId}`, true);
};

export const createTestProfile = (testProfile) => {
  return postReq(`/admin/college/testprofile`, { testProfile }, true);
};

export const getTestProfiles = () => {
  return getReq(`/admin/college/testprofile`, {}, true);
};

export const deleteTestProfile = (testProfileId) => {
  return delReq(`/admin/college/testprofile/${testProfileId}`, {}, true);
};
