import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Well } from "./utils/container";

export const AddCompanyForm = ({ open, handleAdd, handleClose }) => {
  const [companyName, setCompanyName] = useState("");
  const [recruiterName, setRecruiterName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [validTill, setValidTill] = useState(null);

  const onAdd = async () => {
    await handleAdd({
      name: companyName,
      recruiter: {
        name: recruiterName,
        email,
      },
      location,
      validTill,
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Company</DialogTitle>
      <DialogContent>
        <Well title="Company">
          <TextField
            autoFocus
            margin="dense"
            id="company-name"
            label="Name"
            fullWidth
            variant="standard"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="location"
            label="Location"
            fullWidth
            variant="standard"
            multiline
            minRows={6}
            maxRows={8}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
          {/* better to select a plan instead of a date. can have one plan for trial */}
          <TextField
            margin="dense"
            id="validTill"
            label="Valid Till"
            type="date"
            fullWidth
            variant="standard"
            value={validTill}
            onChange={(e) => setValidTill(e.target.value)}
          />
        </Well>
        <Well title="Recruiter">
          <TextField
            autoFocus
            margin="dense"
            id="recruiter-name"
            label="Name"
            fullWidth
            variant="standard"
            value={recruiterName}
            onChange={(e) => setRecruiterName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="admin-email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Well>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditCompanyForm = ({ open, obj, handleSubmit, handleClose }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");

  useEffect(() => {
    setName(obj?.name);
    setLocation(obj?.location);
  }, [obj]);

  const onSubmit = () => {
    handleSubmit({ name, location });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Company</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="location"
          label="Location"
          fullWidth
          variant="standard"
          multiline
          minRows={6}
          maxRows={8}
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
