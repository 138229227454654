import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useBranches } from "../api/college";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddAdminForm = ({ open, handleAdd, handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [branch, setBranch] = useState(null);
  const [error, setError] = useState(null);

  const { branches } = useBranches();

  const onAdd = async () => {
    try {
      await handleAdd({ name, email, branch });
      handleClose();
    } catch (err) {
      console.log(err);
      setError(err.response.data.message || err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Admin</DialogTitle>
      {error && <Alert severity="error">{error}</Alert>}
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Branch"
          select
          fullWidth
          variant="standard"
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
          MenuProps={MenuProps}
          renderValue={(selected) => {
            if (!selected) {
              return <em>Select Branch</em>;
            }

            return branches[selected].title;
          }}
        >
          <MenuItem value={null}>
            <em>None</em>
          </MenuItem>
          {Object.values(branches)?.map((branch) => (
            <MenuItem key={branch._id} value={branch._id}>
              {branch.title}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditAdminForm = ({ open, obj, handleSubmit, handleClose }) => {
  const [name, setName] = useState("");
  const [branch, setBranch] = useState(null);
  const [error, setError] = useState(null);

  const { branches } = useBranches();

  useEffect(() => {
    setName(obj?.name);
    setBranch(obj?.branch?._id);
  }, [obj]);

  const onSubmit = async () => {
    try {
      await handleSubmit({ name, branch });
      handleClose();
    } catch (err) {
      console.log(err);
      setError(err.response.data.message || err.message);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Admin</DialogTitle>
      {error && <Alert severity="error">{error}</Alert>}
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Branch"
          select
          fullWidth
          variant="standard"
          value={branch}
          onChange={(e) => setBranch(e.target.value)}
          MenuProps={MenuProps}
          renderValue={(selected) => {
            if (!selected) {
              return <em>Select Branch</em>;
            }

            return branches[selected].title;
          }}
        >
          {Object.values(branches)?.map((branch) => (
            <MenuItem key={branch._id} value={branch._id}>
              {branch.title}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
