import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FEATURES } from "../utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddStudentForm = ({ open, handleAdd, handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const onAdd = () => {
    handleAdd({ name, email, mobile });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Student</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          fullWidth
          variant="standard"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Phone Number"
          fullWidth
          variant="standard"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export const EditStudentForm = ({ open, obj, handleSubmit, handleClose }) => {
  const [name, setName] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [validTill, setValidTill] = useState(null);
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    setName(obj?.name);
    setEmailVerified(obj?.emailVerified);
    if (obj?.subscription?.validTill) {
      setValidTill(dayjs(obj?.subscription?.validTill).format("YYYY-MM-DD"));
    }
    setFeatures(obj?.features || []);
  }, [obj]);

  const onSubmit = () => {
    handleSubmit({ name, emailVerified, validTill, features });
    handleClose();
  };

  const toggle = (value) => () => {
    const currentIndex = features.indexOf(value);
    const newChecked = [...features];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFeatures(newChecked);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Student</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          margin="dense"
          id="validTill"
          label="Valid Till"
          type="date"
          fullWidth
          variant="standard"
          value={validTill}
          onChange={(e) => setValidTill(e.target.value)}
        />

        <FormControlLabel
          label="Email Verified"
          control={
            <Checkbox
              checked={emailVerified}
              onChange={(e) => setEmailVerified(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />

        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          subheader={<ListSubheader>Features</ListSubheader>}
        >
          {FEATURES.map((feature) => (
            <ListItem>
              <ListItemText primary={feature.name} />
              <Switch
                edge="end"
                onChange={toggle(feature.value)}
                checked={features.indexOf(feature.value) !== -1}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
