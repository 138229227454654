import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { addAdmin, getAdmins, editAdmin, deleteAdmin } from "../api/college";
import { AddAdminForm, EditAdminForm } from "../components/AdminForm";
import TableX from "../components/TableX";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const columns = [
  { field: "name", title: "Name", width: "20%" },
  { field: "email", title: "e-mail", width: "20%" },
  { field: "branch", title: "Branch", width: "10%", render: (r) => r?.title },
];

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [addFormOpen, setAddFormOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getAdmins().then((res) => setAdmins(res.admins));
  }, []);

  const handleClickOpen = () => {
    setAddFormOpen(true);
  };

  const handleClose = () => {
    setAddFormOpen(false);
  };

  const onAddAdmin = async (data) => {
    const res = await addAdmin(data);
    setAdmins([...admins, res.admin]);
  };
  const onEditAdmin = (id) => {
    setEditId(id);
  };
  const onEditSubmit = async (data) => {
    const res = await editAdmin(editId, data);
    setAdmins(
      admins.map((admin) => (admin._id === editId ? res.admin : admin))
    );
  };
  const onDeleteAdmin = (id) => {
    deleteAdmin(id).then((res) => {
      setAdmins(admins.filter((admin) => admin._id !== id));
    });
  };

  return (
    <>
      <TableX
        title="Admins"
        action={
          <Button variant="outlined" onClick={handleClickOpen}>
            Add
          </Button>
        }
        rows={admins}
        columns={columns}
        showIndexColumn
        keyExtractor={(r) => r._id}
        actions={[
          {
            title: "Edit",
            icon: <EditIcon fontSize="small" />,
            onClick: onEditAdmin,
          },
          {
            title: "Delete",
            icon: <DeleteIcon fontSize="small" color="error" />,
            onClick: onDeleteAdmin,
          },
        ]}
      />
      <AddAdminForm
        open={addFormOpen}
        handleAdd={onAddAdmin}
        handleClose={handleClose}
      />
      <EditAdminForm
        open={Boolean(editId)}
        obj={admins.find((s) => s._id === editId)}
        handleSubmit={onEditSubmit}
        handleClose={() => setEditId(null)}
      />
    </>
  );
};

export default Admins;
