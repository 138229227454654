const dayjs = require("dayjs");

export const convertToMap = (listOfObjects, key) => {
  return listOfObjects.reduce((acc, item, index) => {
    acc[key ? item[key] : index] = item;
    return acc;
  }, {});
};

export const groupBy = (listOfObjects, key) => {
  return listOfObjects.reduce((acc, item) => {
    (acc[item[key]] = acc[item[key]] || []).push(item);
    return acc;
  }, {});
};

export const sortByDescending = (listOfObjects, key) => {
  return listOfObjects.sort((a, b) => (dayjs(a[key]) > dayjs(b[key]) ? -1 : 1));
};

export const sortByAscending = (listOfObjects, key) => {
  return listOfObjects.sort((a, b) => (dayjs(a[key]) > dayjs(b[key]) ? 1 : -1));
};

export const unique = (listOfObjects, key) => {
  return Object.values(convertToMap(listOfObjects, key));
};
