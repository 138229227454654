import AddIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  ListSubheader,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { getTopicDetails, getTopics } from "../api/content";
import { preparationPlanModes } from "../utils/constants";
import { convertToMap } from "../utils/object";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Schedule {
  constructor(subTopics = [], mode = "") {
    this.subTopics = subTopics;
    this.mode = mode;
  }
}

const AddButtonInBetween = ({ onClick }) => {
  return (
    <Button
      sx={{ fontSize: "1rem", width: "100%", height: 50 }}
      aria-label="add schedule"
      color="primary"
      size="large"
      onClick={onClick}
    >
      <AddIcon sx={{ fontSize: 20, mr: 2 }} />
      Add day here
    </Button>
  );
};

const PreparationPlanForm = ({ obj, errors: apiErrors, onSubmit }) => {
  const errorRef = useRef(null);

  const [errors, setErrorMessages] = useState([]);
  const [planTitle, setPlanTitle] = useState("");
  const [planDescription, setPlanDescription] = useState("");

  const [scheduleCount, setScheduleCount] = useState(1); // used only as ID in schedule map. actual count is always taken from length of `schedule` variable
  const [schedule, setSchedule] = useState({});
  const [allTopics, setAllTopics] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lastRemovedSchedule, setLastRemovedSchedule] = useState(null);

  useEffect(() => {
    apiErrors && setErrorMessages(apiErrors);
  }, [apiErrors]);

  useEffect(() => {
    if (obj) {
      console.log(obj);
      setPlanTitle(obj.title);
      setPlanDescription(obj.description);
      setSchedule(convertToMap(obj.schedule));
      setScheduleCount(Object.keys(obj.schedule).length);
    } else {
      addSchedule();
    }
  }, [obj]);

  const addSchedule = () => {
    setSchedule((schedule) => {
      const newSchedule = { ...schedule };
      newSchedule[scheduleCount] = { ...new Schedule() };
      setScheduleCount(scheduleCount + 1);
      return newSchedule;
    });
  };

  const addScheduleInBetween = (index) => () => {
    setSchedule((schedule) => {
      const newSchedule = Object.values(schedule)
        .slice(0, index + 1)
        .concat({ ...new Schedule() })
        .concat(Object.values(schedule).slice(index + 1));
      return convertToMap(newSchedule);
    });
  };

  const removeSchedule = (key) => {
    setSchedule((schedule) => {
      const newSchedule = { ...schedule };
      setLastRemovedSchedule({
        key: key,
        schedule: { ...schedule[key] },
      });
      delete newSchedule[key];
      setSnackbarOpen(true);
      setTimeout(() => setSnackbarOpen(false), 3000);
      return newSchedule;
    });
  };

  const moveUp = (index) => {
    setSchedule((schedule) => {
      const newSchedule = { ...schedule };
      const temp = newSchedule[index];
      newSchedule[index] = newSchedule[index - 1];
      newSchedule[index - 1] = temp;
      return newSchedule;
    });
  };

  const moveDown = (index) => {
    setSchedule((schedule) => {
      const newSchedule = { ...schedule };
      const temp = newSchedule[index];
      newSchedule[index] = newSchedule[index + 1];
      newSchedule[index + 1] = temp;
      return newSchedule;
    });
  };

  useEffect(() => {
    loadTopics();
  }, []);

  const loadTopics = async () => {
    const res = await getTopics();
    const { topics } = res;
    const promises = topics.map(async (t) => {
      const res = await getTopicDetails(t.title);
      const { topic } = res;
      return topic;
    });
    const allTopics = await Promise.all(promises);
    setAllTopics(allTopics);
  };

  const onTitleChange = (event) => {
    setPlanTitle(event.target.value);
  };
  const onDescriptionChange = (event) => {
    setPlanDescription(event.target.value);
  };

  const undoDeleteSchedule = () => {
    setSchedule((schedule) => {
      let newSchedule = { ...schedule };
      newSchedule[lastRemovedSchedule.key] = lastRemovedSchedule.schedule;
      setLastRemovedSchedule(null);
      setSnackbarOpen(false);
      return newSchedule;
    });
  };

  const onScheduleTopicsChange = (key, event) => {
    const {
      target: { value },
    } = event;

    setSchedule({
      ...schedule,
      [key]: {
        ...schedule[key],
        subTopics: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  const onScheduleModeChange = (key, event) => {
    const {
      target: { value },
    } = event;

    setSchedule({
      ...schedule,
      [key]: {
        ...schedule[key],
        mode: value,
      },
    });
  };

  const setErrors = (errors) => {
    setErrorMessages(errors);
    if (errors.length !== 0) {
      errorRef.current.scrollIntoView();
    }
  };

  const validate = () => {
    const errors = [];

    if (!planTitle) {
      errors.push("Title is required");
    }
    if (!planDescription) {
      errors.push("Description is required");
    }
    if (!Object.values(schedule).length) {
      errors.push("At least one schedule is required");
    }
    if (Object.values(schedule).some((s) => !s.mode)) {
      errors.push("Schedule mode is required");
    }
    if (Object.values(schedule).some((s) => !s.subTopics)) {
      errors.push("Schedule subtopics are required");
    }

    return errors;
  };

  const handleSubmit = async () => {
    const errors = validate();
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    console.log({
      title: planTitle,
      description: planDescription,
      schedule: Object.values(schedule),
    });
    onSubmit({
      title: planTitle,
      description: planDescription,
      schedule: Object.values(schedule),
    });
  };

  const allSubTopics = Object.values(allTopics).reduce((acc, topic) => {
    topic.subTopics.forEach((subTopic) => {
      acc[subTopic._id] = subTopic;
    });
    return acc;
  }, {});

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
          width: "100%",
          height: "100%",
        }}
        ref={errorRef}
      >
        <FormControl
          sx={(theme) => ({
            m: 1,
            width: "100%",
            mt: 2,
            [theme.breakpoints.down("md")]: {
              p: 1,
              m: 1,
            },
          })}
        >
          <Typography variant="h4" sx={{ m: 1 }}>
            {obj ? "Edit" : "Create"} Preparation Plan
          </Typography>
          {errors?.length > 0 && (
            <Alert severity="error" sx={{ m: 1, width: "auto", mt: 2, mb: 4 }}>
              <AlertTitle>Error</AlertTitle>
              <ul>
                {errors.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </Alert>
          )}

          <TextField
            id="outlined-basic"
            label="Title"
            value={planTitle}
            onChange={onTitleChange}
            sx={{ m: 1 }}
          />
          <TextField
            id="outlined-basic"
            label="Description"
            value={planDescription}
            onChange={onDescriptionChange}
            sx={{ m: 1 }}
            type="text"
            multiline
          />
          <Divider />
          <AddButtonInBetween onClick={addScheduleInBetween(-1)} />
          {Object.keys(schedule).map((key, index) => (
            <>
              <Paper
                key={key}
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  m: 1,
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    pl: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Typography variant="h6">Day {index + 1}</Typography>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                    >
                      <IconButton
                        onClick={() => moveUp(index)}
                        disabled={index === 0}
                      >
                        <KeyboardArrowUpIcon
                          color={index === 0 ? "disabled" : "primary"}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => moveDown(index)}
                        disabled={index === scheduleCount}
                      >
                        <KeyboardArrowDownIcon
                          color={
                            index === scheduleCount ? "disabled" : "primary"
                          }
                        />
                      </IconButton>
                    </ButtonGroup>
                    <Divider orientation="vertical" flexItem />
                    <IconButton onClick={() => removeSchedule(key)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 3, display: "flex", flexDirection: "column" }}>
                  <Select
                    multiple
                    displayEmpty
                    value={schedule[key]?.subTopics || []}
                    onChange={(e) => onScheduleTopicsChange(key, e)}
                    renderValue={(selected) => {
                      if (!selected || selected.length === 0)
                        return <em>Select Sub-Topics</em>;
                      return selected
                        .map((id) => allSubTopics[id]?.title)
                        .join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ m: 1, ml: 0, mr: 0 }}
                  >
                    {Object.values(allTopics)?.map((topic) =>
                      [null, ...topic.subTopics].map((subTopic, index) => {
                        if (index === 0) {
                          return (
                            <ListSubheader key={topic._id}>
                              {topic.title}
                            </ListSubheader>
                          );
                        }
                        return (
                          <MenuItem key={subTopic._id} value={subTopic._id}>
                            {subTopic.title}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                  <FormControl>
                    <FormLabel>Mode</FormLabel>
                    <RadioGroup
                      defaultValue={preparationPlanModes.STUDY}
                      value={schedule[key]?.mode}
                      onChange={(e) => onScheduleModeChange(key, e)}
                    >
                      <FormControlLabel
                        value={preparationPlanModes.STUDY}
                        control={<Radio />}
                        label="Learn"
                      />
                      <FormControlLabel
                        value={preparationPlanModes.PRACTICE}
                        control={<Radio />}
                        label="Test"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Paper>
              <AddButtonInBetween onClick={addScheduleInBetween(index)} />
            </>
          ))}

          <Button
            variant="contained"
            sx={{ m: 1, mt: 4 }}
            onClick={handleSubmit}
            size="large"
          >
            Submit
          </Button>
        </FormControl>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message="Deleted schedule"
          action={
            <React.Fragment>
              <Button color="primary" size="small" onClick={undoDeleteSchedule}>
                UNDO
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setSnackbarOpen(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </Box>
  );
};

export default PreparationPlanForm;
