import { getReq, postFileReq, postReq } from ".";

export const addCertificate = ({ email }) => {
  return postReq("/superadmin/certificate", { userEmail: email }, true);
};

export const uploadCertificate = async ({ email, file }) => {
  const res = await postFileReq(
    `/superadmin/certificate/${email}`,
    null,
    true,
    file
  );
  return res;
};

export const getAllCertificates = () => {
  return getReq("/superadmin/certificate");
};

export const getCertificate = (certificateId) => {
  return getReq(`/certificate/${certificateId}`);
};
