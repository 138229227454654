import { useEffect, useState } from "react";
import { Button, List, ListItem, Paper, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NoticeBoardPostCard from "../components/NoticeBoardPostCard";
import {
  getNoticeBoardPosts,
  createNoticeBoardPost,
  updateNoticeBoardPost,
  deleteNoticeBoardPost,
} from "../api/college";
import { Box } from "@mui/system";
import NoticeBoardPostForm from "../components/NoticeBoardPostForm";
import Loading from "../components/Loading";
import withAuth from "../hoc/withAuth";

const NoticeBoard = ({ user }) => {
  const [posts, setPosts] = useState([]);
  const [open, setOpen] = useState(false);
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPosts();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onAddPost = async (post) => {
    const res = await createNoticeBoardPost(post);
    setPosts([res.post, ...posts]);
    handleClose();
  };

  const onEditPost = async (id, post) => {
    const res = await updateNoticeBoardPost(id, post);
    setPosts(posts.map((p) => (p._id === id ? res.post : p)));
    handleClose();
  };

  const getPosts = async () => {
    try {
      setLoading(true);
      const res = await getNoticeBoardPosts();
      setPosts(res.noticeboard);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onEdit = (id) => {
    setEditId(id);
    handleClickOpen();
  };

  const onDelete = async (id) => {
    await deleteNoticeBoardPost(id);
    setPosts(posts.filter((post) => post._id !== id));
  };

  const onFormSubmit = (post) => {
    if (editId) {
      onEditPost(editId, post);
    } else {
      onAddPost(post);
    }
  };

  return (
    <Box
      sx={{
        width: "60%",
        margin: "0 auto",
        overflow: "scroll",
      }}
    >
      <NoticeBoardPostForm
        open={open}
        onSubmit={onFormSubmit}
        handleClose={handleClose}
        obj={posts.find((post) => post._id === editId)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Notice Board</Typography>
        <Button variant="text" endIcon={<AddIcon />} onClick={handleClickOpen}>
          Add New Post
        </Button>
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <List>
          {posts.length > 0 ? (
            posts.map((post) => {
              return (
                <ListItem key={post._id}>
                  <NoticeBoardPostCard
                    post={post}
                    userId={user?._id}
                    onEdit={() => onEdit(post._id)}
                    onDelete={() => onDelete(post._id)}
                  />
                </ListItem>
              );
            })
          ) : (
            <Paper
              sx={{
                width: "auto",
                height: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="outlined"
            >
              <Typography variant="body1">No posts yet</Typography>
            </Paper>
          )}
        </List>
      )}
    </Box>
  );
};

export default withAuth(NoticeBoard);
