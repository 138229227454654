import useSWR from "swr";
import { delReq, getBasePath, getReq, postReq, putReq } from ".";

export const useBranches = () => {
  const { data = { branches: [] } } = useSWR("/admin/college/branch", getReq, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return {
    branches: data.branches.reduce(
      (acc, curr) => ({ ...acc, [curr._id]: curr }),
      {}
    ),
  };
};

export const useStudents = () => {
  const {
    data = { students: [] },
    isLoading,
    error,
    mutate,
  } = useSWR(`/admin/college/student`, getReq, {
    revalidateOnFocus: false,
  });
  return {
    students: data.students,
    isLoading,
    error,
    mutate: (students) => mutate({ students }),
  };
};

export const useStudent = (user, id) => {
  const basePath = getBasePath(user?.role);
  const {
    data = {
      details: {},
      activity: {},
      progress: [],
    },
    isLoading,
    error,
  } = useSWR(`${basePath}/student/${id}`, getReq, {
    revalidateOnFocus: false,
  });

  return { student: data, isLoading, error };
};

export const addStudent = (data) => {
  return postReq("/admin/college/student", data);
};

export const editStudent = (id, data) => {
  return putReq(`/admin/college/student/${id}`, data);
};

export const deleteStudent = (id) => {
  return delReq(`/admin/college/student/${id}`);
};

export const useAllTestsOfUser = (user, studentId) => {
  const basePath = getBasePath(user?.role);
  const {
    data = { tests: [] },
    mutate,
    isLoading,
    isValidating,
    error,
  } = useSWR(`${basePath}/student/${studentId}/test`, getReq, {
    revalidateOnFocus: false,
  });
  const { tests } = data;
  tests.sort((a, b) => {
    return new Date(b.startTime) - new Date(a.startTime);
  });
  return {
    tests,
    isLoading,
    isValidating,
    error,
    mutate: (tests) => mutate({ tests }),
  };
};

export const getStudentsReportsOfAllTests = () => {
  return getReq(`/admin/college/report`);
};

export const getTestReport = (testId) => {
  return getReq(`/admin/college/test/${testId}/report`);
};

export const getTestReportOfStudent = (user, { studentId, testId }) => {
  const basePath = getBasePath(user?.role);
  return getReq(`${basePath}/student/${studentId}/test/${testId}`);
};

export const getAdmins = () => {
  return getReq("/admin/college/admin");
};

export const addAdmin = (data) => {
  return postReq("/admin/college/admin", data);
};

export const editAdmin = (id, data) => {
  return putReq(`/admin/college/admin/${id}`, data);
};

export const deleteAdmin = (id) => {
  return delReq(`/admin/college/admin/${id}`);
};

export const getNoticeBoardPosts = () => {
  return getReq("/admin/college/noticeboard");
};

export const createNoticeBoardPost = (data) => {
  return postReq("/admin/college/noticeboard", data);
};

export const updateNoticeBoardPost = (id, data) => {
  return putReq(`/admin/college/noticeboard/${id}`, data);
};

export const deleteNoticeBoardPost = (id) => {
  return delReq(`/admin/college/noticeboard/${id}`);
};

export const getNoticeBoardPostReplies = (postId) => {
  return getReq(`/admin/college/noticeboard/${postId}/reply`);
};

export const createNoticeBoardPostReply = ({ postId, message }) => {
  return postReq(`/admin/college/noticeboard/${postId}/reply`, { message });
};

export const updateNoticeBoardPostReply = ({ postId, replyId, message }) => {
  return putReq(`/admin/college/noticeboard/${postId}/reply/${replyId}`, {
    message,
  });
};

export const deleteNoticeBoardPostReply = ({ postId, replyId }) => {
  return delReq(`/admin/college/noticeboard/${postId}/reply/${replyId}`);
};
