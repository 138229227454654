import { Paper, Typography } from "@mui/material";
import dayjs from "dayjs";

const StudentActivity = ({ data: activity }) => {
  return (
    <Paper variant="outlined" className="student-info-container">
      <Typography variant="h6" gutterBottom>
        Last Active
      </Typography>
      <Typography variant="body1">
        {activity.lastActive
          ? dayjs(activity.lastActive).format("DD MMM YYYY, h:mm A")
          : "No activity yet"}
      </Typography>
    </Paper>
  );
};

export default StudentActivity;
