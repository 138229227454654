import React, { useRef, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTest, getTest, updateTest } from "../api/test";
import routes from "../layout/routes";
import TestForm from "../components/TestForm";
import { useParams } from "react-router";

const CreateTest = () => {
  const navigate = useNavigate();
  const errorRef = useRef(null);

  const [errors, setErrorMessages] = useState([]);
  const [test, setTest] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchTest();
    }
  }, [id]);

  const fetchTest = async () => {
    try {
      const res = await getTest(id);
      setTest(res.data.test);
    } catch (err) {
      console.error(err);
    }
  };

  const setErrors = (errors) => {
    setErrorMessages(errors);
    if (errors.length !== 0) {
      errorRef.current.scrollIntoView();
    }
  };

  const onSubmit = (...args) => {
    if (id) {
      onEdit(...args);
    } else {
      onCreate(...args);
    }
  };

  const onCreate = async (test) => {
    try {
      await createTest(test);
      setErrors([]);
      navigate(routes.tests.path);
    } catch (err) {
      console.error("error", err.response);
      if (err.response?.data?.message) {
        setErrors(err.response.data.message.split("\n"));
      } else {
        setErrors(["Unknown error"]);
      }
    }
  };

  const onEdit = async (test) => {
    try {
      await updateTest(id, test);
      setErrors([]);
      navigate(routes.tests.path);
    } catch (err) {
      console.error("error", err.response);
      if (err.response?.data?.message) {
        setErrors(err.response.data.message.split("\n"));
      } else {
        setErrors(["Unknown error"]);
      }
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
          width: "100%",
          height: "100%",
        }}
        ref={errorRef}
      >
        <TestForm obj={test} errors={errors} onSubmit={onSubmit} />
      </div>
    </Box>
  );
};

export default CreateTest;
