import { LoadingButton } from "@mui/lab";
import { Alert, Button, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useTransactionsOfUser } from "../api/superAdmin";
import withAuth from "../hoc/withAuth";
import { TRANSACTION_STATUS } from "../utils/constants";
import TableX from "./TableX";
import TransactionDetails from "./TransactionDetails";
import DialogX from "./utils/Dialog";

const StudentTransactions = ({ studentId }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const { transactions, mutate, isLoading, isValidating, error } =
    useTransactionsOfUser(studentId);

  const columns = useMemo(
    () => [
      {
        title: "EH transaction ID",
        field: "merchantTransactionId",
        width: "30%",
      },
      {
        title: "Status",
        field: "status",
        width: "20%",
        render: (v) => (
          <Typography color={TRANSACTION_STATUS[v]?.color}>
            {TRANSACTION_STATUS[v]?.label}
          </Typography>
        ),
        filterValue: (row) => ({
          value: row?.status,
          title: TRANSACTION_STATUS[row?.status]?.label,
        }),
      },
      {
        title: "Date",
        field: "createdAt",
        width: "25%",
        render: (v) => (
          <Typography variant="body2">
            {dayjs(v).format("DD MMM YYYY")}
          </Typography>
        ),
        onSort: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      },
      {
        title: "Actions",
        width: "25%",
        render: (_, row) => (
          <Button variant="text" onClick={() => setSelectedTransaction(row)}>
            Details
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <Paper variant="outlined">
      {error && <Alert severity="error">{error.message}</Alert>}
      <DialogX
        open={Boolean(selectedTransaction)}
        handleClose={() => setSelectedTransaction(null)}
        title="Transaction Details"
        content={<TransactionDetails data={selectedTransaction || {}} />}
        primaryAction={() => setSelectedTransaction(null)}
        primaryActionTitle="Done"
      />
      <TableX
        title="Transactions"
        loading={isLoading}
        action={
          <LoadingButton
            loading={isLoading || isValidating}
            variant="text"
            onClick={() => mutate([])}
          >
            Refresh
          </LoadingButton>
        }
        showIndexColumn
        keyExtractor={(r) => r._id}
        style={{ width: "auto" }}
        tableStyle={{ width: "100%", mx: 0 }}
        columns={columns}
        rows={transactions}
      />
    </Paper>
  );
};

export default withAuth(StudentTransactions);
